import React, { Suspense, useEffect, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { refreshPageAndRestoreUserState } from "src/helpers";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch, useSelector } from "react-redux";
import * as appActions from "src/redux/app/app-actions";
import "sweetalert2/src/sweetalert2.scss";
import { Routes } from "./Routes";

export function App() {
    const dispatch = useDispatch();
    const appInitialized = useSelector((state) => state.app.appInitialized);

    const onLogin = useCallback(
        ({ userRaw, userInfo, userStorage }) => {
            dispatch(appActions.onLogin({ userRaw, userInfo, userStorage }));
        },
        [dispatch]
    );

    const setAppInitialized = useCallback(
        (appInitialized) => {
            dispatch(appActions.setAppInitialized({ appInitialized }));
        },
        [dispatch]
    );

    useEffect(() => {
        (async function () {
            if (!appInitialized) {
                await refreshPageAndRestoreUserState({ onLogin, setAppInitialized });
            }
        })();
    }, [appInitialized, onLogin, setAppInitialized]);

    return (
        <>
            <CssBaseline />
            <Router>
                <Suspense fallback={<div>Loading.....</div>}>
                    <Routes />
                </Suspense>
            </Router>
        </>
    );
}
