import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "src/images/close-icon.svg";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, ClickAwayListener } from "@mui/material";
import { useFeedContainerContext } from "../FeedContainer";
// import BookmarkIcon from "images/bookmark.svg";
// import LikeIcon from "images/like.svg";

const useStyles = makeStyles({
    dislikeRoot: {
        position: "relative",
    },
    dislikeDropdown: {
        position: "absolute",
        top: 28,
        right: 0,
        zIndex: 1,
        backgroundColor: "#fff",
        width: "200px",
        borderRadius: "4px",
        paddingTop: "8px",
        paddingBottom: "8px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
        "& > div": {
            width: "100%",
            height: "48px",
            fontSize: "16px",
            lineHeight: "24px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "12px 16px",
            color: "#000",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#E3F2FD",
            },
        },
    },
});

export function StoryItemActions(props) {
    const { handeDislikeReasonOnClick } = props;
    const classes = useStyles();

    const { storyItemConfigs } = useFeedContainerContext();

    const [dislikeDropdownOpen, setDislikeDropdownOpen] = useState(false);

    const handleDislikeButtonClick = useMemo(() => {
        return () => setDislikeDropdownOpen((prev) => !prev);
    }, []);

    const handleDislikeClickAway = useMemo(() => {
        return () => setDislikeDropdownOpen(false);
    }, []);

    return (
        <>
            <div className="tt_feed_actions">
                {/* <LikeIcon /> */}
                {/* <span className="tt_feed_likes_count"></span> */}
                {/* <BookmarkIcon /> */}
                <ClickAwayListener onClickAway={handleDislikeClickAway}>
                    <div className={classes.dislikeRoot}>
                        <IconButton
                            onClick={handleDislikeButtonClick}
                            color="primary"
                            aria-label="dislike news"
                            component="span"
                        >
                            <CloseIcon />
                        </IconButton>
                        {dislikeDropdownOpen && (
                            <Box className={classes.dislikeDropdown}>
                                {!storyItemConfigs.hideBlockWebsiteOption && (
                                    <Box
                                        data-reason="block_site"
                                        onClick={handeDislikeReasonOnClick}
                                    >
                                        Hide this website
                                    </Box>
                                )}
                                <Box
                                    data-reason="not_interested"
                                    onClick={handeDislikeReasonOnClick}
                                >
                                    Not interested
                                </Box>
                                <Box data-reason="low_quality" onClick={handeDislikeReasonOnClick}>
                                    Low quality
                                </Box>
                                <Box data-reason="irrelevant" onClick={handeDislikeReasonOnClick}>
                                    Irrelevant
                                </Box>
                            </Box>
                        )}
                    </div>
                </ClickAwayListener>
            </div>
        </>
    );
}

StoryItemActions.propTypes = {
    handeDislikeReasonOnClick: PropTypes.func.isRequired,
};
