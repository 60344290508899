import { cloneDeep } from "lodash";

// * k means const, common convention in backend
export const kFullFilterObject = {
    storyTypes: {
        // Use the singular form
        curated_source: { value: "curated_source", label: "Curated source" },
        sec_filing: { value: "sec_filing", label: "SEC filing" },
        financial_sec_filing: { value: "financial_sec_filing", label: "Financial report" },
        ipo_sec_filing: { value: "ipo_sec_filing", label: "IPO SEC filing" },
        earnings_call: { value: "earnings_call", label: "Earnings Call" },
    },
    exclusions: {
        ugc: { value: "ugc", label: "UGC stories (e.g. Reddit)" },
        trading_activity: { value: "trading_activity", label: "Trading Activity" },
        market_news: { value: "market_news", label: "Market News" },
    },
};

export const kDefaultFilter = () => {
    const _kDefaultFilter = {
        // An empty array means all types are acceptable.
        storyTypes: [],
        exclusions: ["ugc", "trading_activity", "market_news"],
        // All options: us_rss, cn_rss,
        // This one is not exposed to users.
        // An empty array means all sources are acceptable.
        // source_types: [],
    };
    return cloneDeep(_kDefaultFilter);
};

/**
 *
 */
export function convertFilter2KV(filter) {
    const exclArray = filter?.exclusions || [];
    const storyTypeArray = filter?.storyTypes || [];

    const filterKV = {
        exclusions: {},
        storyTypes: {},
    };

    for (const excl of exclArray) {
        filterKV.exclusions[excl] = true;
    }

    for (const storyType of storyTypeArray) {
        filterKV.storyTypes[storyType] = true;
    }

    return filterKV;
}

/**
 *
 */
export function convertKV2Filter(filterKV) {
    const filter = {
        exclusions: [],
        storyTypes: [],
    };

    const exclObj = filterKV.exclusions;
    const storyTypesObj = filterKV.storyTypes;

    for (const [excl, exclValueBool] of Object.entries(exclObj)) {
        if (exclValueBool) {
            filter.exclusions.push(excl);
        }
    }

    for (const [storyType, storyTypeValueBool] of Object.entries(storyTypesObj)) {
        if (storyTypeValueBool) {
            filter.storyTypes.push(storyType);
        }
    }

    return filter;
}
