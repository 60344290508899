import { LAMBDA_BASE_URL } from "src/api";
import { getAccessToken } from "./auth-helpers";

/**
 * ! Note: this function only used when users signup by email and password (aws cognito)
 */
export async function createUserData(accessToken, userId, email) {
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({
            operation: "CREATE_USER_DATA",
            userId,
            email,
            accessToken,
        }),
    });

    const result = await response.json();
    return result;
}

export async function updateFilter({ userId, whichFilter, filterValue }) {
    const accessToken = await getAccessToken();
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({
            operation: "UPDATE_FILTER",
            userId,
            whichFilter,
            filterValue,
            accessToken,
        }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }
    return data.result;
}

export async function getUserDataById(userId) {
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({ operation: "GET_USER_DATA_BY_ID", userId }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }
    return data.result;
}

export const updateWatchlists = async ({ userId, watchlists }) => {
    const accessToken = await getAccessToken();
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({ operation: "UPDATE_WATCHLISTS", accessToken, userId, watchlists }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }
    return data.result;
};

export async function shareWatchlist({ userId, name, tickers, prefix, filters, blocked_websites }) {
    const accessToken = await getAccessToken();
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({
            operation: "SHARE_WATCHLIST",
            accessToken,
            userId,
            tickers,
            prefix,
            filters,
            name,
            blocked_websites,
        }),
    });
    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }
    return data.result;
}

export async function getOneSharedWatchlist(shareWatchlistId) {
    const response = await fetch(`${LAMBDA_BASE_URL}/crud`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({
            operation: "GET_ONE_SHARED_WATCHLIST",
            shareWatchlistId,
        }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }
    return data.result.Item;
}
