export const LocalStorageKeys = {
    UserRaw: "TickerTick.UserRaw",
    LoadMoreStatus: "TickerTick.LoadMoreStatus",
};

export const AuthProvider = {
    COGNITO: "cognito",
    TWITTER: "twitter",
    GOOGLE: "google",
    GITHUB: "github",
    FACEBOOK: "facebook",
};

export const CURRENT_FIREBASE_AUTH_PROVIDER = [
    AuthProvider.TWITTER,
    AuthProvider.GOOGLE,
    AuthProvider.GITHUB,
];

export const defaultStartupTickers = [
    ".bytedance",
    ".spacex",
    ".shein",
    ".stripe",
    ".canva",
    ".checkout.com",
    ".instacart",
    ".databricks",
    ".revolut",
    ".ftx",
];
