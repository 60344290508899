import React from "react";
import PropTypes from "prop-types";
import { BaseSideNav } from "./BaseSideNav";
import { useWindowSize } from "react-use";

export function SideNav({ setDisplaySidenav }) {
    const { width } = useWindowSize();

    return width > 1280 ? <BaseSideNav setDisplaySidenav={setDisplaySidenav} /> : null;
}

SideNav.propTypes = {
    setDisplaySidenav: PropTypes.func,
};
