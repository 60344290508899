import React, { useState, useCallback } from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BaseSideNav } from "src/sidenav";
import { useWindowSize } from "react-use";

const useStyles = makeStyles(() => ({
    MobileSideNavTriggerButton: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&:hover": {
            backgroundColor: "#E5E5E5",
        },
        padding: 0,
        "& .MuiIconButton-root:hover": {
            backgroundColor: "unset",
        },
        marginLeft: "10px",
        height: "44px",
    },
}));

export function MobileSideNav() {
    const classes = useStyles();
    const { width } = useWindowSize();
    const [displaySidenav, setDisplaySidenav] = useState(false);
    const showSideNav = useCallback(() => {
        if (displaySidenav) return;
        setDisplaySidenav(true);
    }, [displaySidenav]);

    // ! TODO: use drawer to refactor this
    return (
        <React.Fragment>
            {width <= 1280 && (
                <Button className={classes.MobileSideNavTriggerButton} onClick={showSideNav}>
                    <IconButton component="span">
                        <MenuIcon />
                    </IconButton>
                </Button>
            )}
            {displaySidenav && width <= 1280 && (
                <BaseSideNav setDisplaySidenav={setDisplaySidenav} />
            )}
        </React.Fragment>
    );
}
