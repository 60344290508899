import { createAction } from "@reduxjs/toolkit";

export const ON_LOGGING_USER_BEHAVIOR = "USER.ON_LOGGING_USER_BEHAVIOR";
export const onLoggingUserBehavior = createAction(ON_LOGGING_USER_BEHAVIOR);

export const ON_LOGGING_USER_BEHAVIOR_DONE = "USER.ON_LOGGING_USER_BEHAVIOR_DONE";
export const onLoggingUserBehaviorDone = createAction(ON_LOGGING_USER_BEHAVIOR_DONE);

export const ON_UPDATE_BLOCKLISTS = "USER.ON_UPDATE_BLOCKLISTS";
export const onUpdateBlocklists = createAction(ON_UPDATE_BLOCKLISTS);

export const ON_UPDATE_BLOCKLISTS_DONE = "USER.ON_UPDATE_BLOCKLISTS_DONE";
export const onUpdateBlocklistsDone = createAction(ON_UPDATE_BLOCKLISTS_DONE);
