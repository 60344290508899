import React from "react";
import { Box } from "@mui/material";
import { Search } from "src/components";

export function EmptyWatchlist() {
    return (
        <Box
            component="div"
            width="100%"
            margin="0px"
            padding="0px"
            bgcolor="#fbfcfd"
            borderLeft="1px solid #e5e5e5"
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxWidth="1200px"
        >
            <Box maxWidth="980px" width="100%" height="100%">
                <Search />
                <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    flexGrow={1}
                >
                    <h1>This watchlist is empty. Please search some tickers and add them to it.</h1>
                </Box>
            </Box>
        </Box>
    );
}

EmptyWatchlist.displayName = "EmptyWatchlist";
