import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import { lazy } from "@loadable/component";
import { NotFound } from "src/components";

import { withLayout } from "./withLayout";

const Home = lazy(() => import(/* webpackChunkName: "home" */ "src/views/home"));
const Startup = lazy(() => import(/* webpackChunkName: "startup" */ "src/views/startup"));

const UserAuth = lazy(() => import(/* webpackChunkName: "user-auth" */ "src/views/user/auth"));

const Watchlist = lazy(() => import(/* webpackChunkName: "watchlist" */ "src/views/watchlist"));
const Ipo = lazy(() => import(/* webpackChunkName: "ipo" */ "src/views/ipo"));
const Unsubscribe = lazy(() =>
    import(/* webpackChunkName: "unsubscribe" */ "src/views/unsubscribe")
);

const SharedWatchlistFeed = lazy(() =>
    import(
        /* webpackChunkName: "shared-watchlist-feed" */ "src/views/shared/watchlist/WatchlistFeed"
    )
);

const Ticker = lazy(() => import(/* webpackChunkName: "ticker" */ "src/views/ticker"));

const WithLayoutHome = withLayout(Home);
const WithLayoutStartup = withLayout(Startup);
const WithLayoutNotFound = withLayout(NotFound);
const WithLayoutUnsubscribe = withLayout(Unsubscribe);
const WithLayoutTicker = withLayout(Ticker);
const WithLayoutWatchlist = withLayout(Watchlist);
const WithLayoutIpo = withLayout(Ipo);
const WithLayoutSharedWatchlistFeed = withLayout(SharedWatchlistFeed);

export const Routes = () => {
    return (
        <Switch>
            <Route path="/" element={<WithLayoutHome />} />
            <Route path="/startup" element={<WithLayoutStartup />} />
            <Route path="/unsubscribe/:item" element={<WithLayoutUnsubscribe />} />

            <Route path="/ticker/:tickerSymbol/feed" element={<WithLayoutTicker />} />
            <Route path="/watchlist/:watchlistId" element={<WithLayoutWatchlist />} />
            <Route path="/ipo/:status" element={<WithLayoutIpo />} />
            <Route
                path="/shared/watchlist/:sharedWatchlistId"
                element={<WithLayoutSharedWatchlistFeed />}
            />

            <Route path="/user/auth" element={<UserAuth />} />
            <Route path="*" element={<WithLayoutNotFound />} />
        </Switch>
    );
};
