export const buildTwitterUrl = (title, url, tags) => {
    const urlObj = new URL("https://twitter.com/intent/tweet");
    const params = new URLSearchParams();
    params.append(
        "text",
        `\n\nMore $${tags[0].toUpperCase()} news on #TickerTick https://tickertick.com/ticker/${
            tags[0]
        }/feed\n`
    );
    params.append("url", url);
    // params.append("hashtags", "TickerTick");
    // params.append("via", "TickerTick3");
    urlObj.search = params;
    return urlObj.toString();
};
