import React from "react";
import PropTypes from "prop-types";

import { MainViewPlaceholder } from "./MainViewPlaceholder";

export function NotFound({ text = "404 Not Found" }) {
    return (
        <MainViewPlaceholder>
            <h1>{text}</h1>
        </MainViewPlaceholder>
    );
}

NotFound.propTypes = {
    text: PropTypes.string,
};
