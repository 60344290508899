import React, { useCallback } from "react";
import { List, Collapse } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as appActions from "src/redux/app/app-actions";
import "./NavItems.scss";
import { NavItem } from "./NavItem";
import {
    EmojiObjects as EmojiObjectsOutlinedIcon,
    BookmarkBorder as BookmarkBorderOutlinedIcon,
    ExpandMore as ExpandMoreIcon,
    Flight as FlightIcon,
    AccessAlarms as AccessAlarmsIcon,
    RocketLaunch as RocketLaunchIcon,
} from "@mui/icons-material";
import { NAV_ITEM_VALUE } from "./consts";
import { GA_EVENT_LABEL, GA_EVENT_ACTION, GA_EVENT_CATEGORY } from "src/helpers";

const icons = {
    [NAV_ITEM_VALUE.TOP_STORIES]: <EmojiObjectsOutlinedIcon />,
    [NAV_ITEM_VALUE.SAVED_STORIES]: <BookmarkBorderOutlinedIcon />,
    [NAV_ITEM_VALUE.WATCHLISTS]: <ExpandMoreIcon />,
    [NAV_ITEM_VALUE.RECENT_IPO]: <FlightIcon />,
    [NAV_ITEM_VALUE.UPCOMING_IPO]: <AccessAlarmsIcon />,
    [NAV_ITEM_VALUE.STARTUP_NEWS]: <RocketLaunchIcon />,
};

export function NavItems() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuth = useSelector((state) => state.app.isAuth);
    const userInfo = useSelector((state) => state.app.userInfo);
    const watchlists = useSelector((state) => state.app.watchlists);
    const activeSideNav = useSelector((state) => state.app.activeSideNav);
    const activeWatchlist = useSelector((state) => state.app.activeWatchlist);
    const sideNavWatchlistExpand = useSelector((state) => state.app.sideNavWatchlistExpand);

    const setWatchlists = useCallback(
        (watchlists) => {
            dispatch(appActions.setWatchlists({ watchlists }));
        },
        [dispatch]
    );

    const setActiveWatchlist = useCallback(
        (watchlistId) => {
            dispatch(appActions.setActiveWatchlist({ activeWatchlist: watchlistId }));
        },
        [dispatch]
    );

    const onGoHome = useCallback(() => {
        dispatch(appActions.onGoHome());
    }, [dispatch]);

    const setSideNavWatchlistExpand = useCallback(
        (expandValue) => {
            dispatch(
                appActions.setSidenavWatchlistExpand({
                    sideNavWatchlistExpand: expandValue,
                })
            );
        },
        [dispatch]
    );

    const handleTopStoriesOnClick = useCallback(
        (itemValue) => {
            if (activeSideNav !== itemValue) {
                dispatch(appActions.setActiveSideNav({ activeSideNav: itemValue }));
            }
            if (sideNavWatchlistExpand !== false) {
                setSideNavWatchlistExpand(false);
            }
            navigate("/");
            // * clean up
            if (isAuth && activeWatchlist !== null) {
                dispatch(appActions.setActiveWatchlist({ activeWatchlist: null }));
            }

            window.ReactGA?.event({
                category: GA_EVENT_CATEGORY.USER,
                action: GA_EVENT_ACTION.CLICK_SIDE_NAV,
                label: GA_EVENT_LABEL.TOP_STORIES,
            });
        },
        [
            activeSideNav,
            activeWatchlist,
            dispatch,
            navigate,
            isAuth,
            setSideNavWatchlistExpand,
            sideNavWatchlistExpand,
        ]
    );

    const handleStartupNewsOnClick = useCallback(
        (itemValue) => {
            if (activeSideNav !== itemValue) {
                dispatch(appActions.setActiveSideNav({ activeSideNav: itemValue }));
            }

            if (sideNavWatchlistExpand !== false) {
                setSideNavWatchlistExpand(false);
            }

            navigate("/startup");

            // * clean up
            if (isAuth && activeWatchlist !== null) {
                dispatch(appActions.setActiveWatchlist({ activeWatchlist: null }));
            }
        },
        [
            activeSideNav,
            activeWatchlist,
            dispatch,
            isAuth,
            navigate,
            setSideNavWatchlistExpand,
            sideNavWatchlistExpand,
        ]
    );

    const handleRecentIpoOnClick = useCallback(
        (itemValue) => {
            if (activeSideNav !== itemValue) {
                dispatch(appActions.setActiveSideNav({ activeSideNav: itemValue }));
            }
            if (sideNavWatchlistExpand !== false) {
                setSideNavWatchlistExpand(false);
            }
            navigate("/ipo/recent");
            // * clean up
            if (isAuth && activeWatchlist !== null) {
                dispatch(appActions.setActiveWatchlist({ activeWatchlist: null }));
            }

            window.ReactGA?.event({
                category: GA_EVENT_CATEGORY.USER,
                action: GA_EVENT_ACTION.CLICK_SIDE_NAV,
                label: GA_EVENT_LABEL.RECENT_IPO,
            });
        },
        [
            activeSideNav,
            activeWatchlist,
            dispatch,
            navigate,
            isAuth,
            setSideNavWatchlistExpand,
            sideNavWatchlistExpand,
        ]
    );

    const handleWatchlistOnClick = useCallback(
        (itemValue) => {
            if (activeSideNav !== itemValue) {
                dispatch(appActions.setActiveSideNav({ activeSideNav: itemValue }));
            }
            setSideNavWatchlistExpand(!sideNavWatchlistExpand);

            window.ReactGA?.event({
                category: GA_EVENT_CATEGORY.USER,
                action: GA_EVENT_ACTION.CLICK_SIDE_NAV,
                label: GA_EVENT_LABEL.WATCHLISTS,
            });
        },
        [activeSideNav, setSideNavWatchlistExpand, sideNavWatchlistExpand, dispatch]
    );

    const handleWatchlistItemOnClick = useCallback(
        (itemValue) => {
            if (activeWatchlist !== itemValue) {
                dispatch(appActions.setActiveWatchlist({ activeWatchlist: itemValue }));
                navigate(`/watchlist/${itemValue}`);
            }
            window.ReactGA?.event({
                category: GA_EVENT_CATEGORY.USER,
                action: GA_EVENT_ACTION.CLICK_WATCHLIST,
                label: itemValue,
            });
        },
        [activeWatchlist, dispatch, navigate]
    );

    return (
        <List className="SideNav-items">
            <NavItem
                itemValue={NAV_ITEM_VALUE.TOP_STORIES}
                itemLabel="Top Stories"
                isSelected={activeSideNav === NAV_ITEM_VALUE.TOP_STORIES}
                icons={icons}
                handleNavItemOnClick={handleTopStoriesOnClick}
            />
            <NavItem
                itemValue={NAV_ITEM_VALUE.RECENT_IPO}
                itemLabel="IPOs"
                isSelected={activeSideNav === NAV_ITEM_VALUE.RECENT_IPO}
                icons={icons}
                handleNavItemOnClick={handleRecentIpoOnClick}
            />
            <NavItem
                itemValue={NAV_ITEM_VALUE.STARTUP_NEWS}
                itemLabel="Startup news"
                isSelected={activeSideNav === NAV_ITEM_VALUE.STARTUP_NEWS}
                icons={icons}
                handleNavItemOnClick={handleStartupNewsOnClick}
            />
            {isAuth && watchlists?.length > 0 && (
                <NavItem
                    itemValue={NAV_ITEM_VALUE.WATCHLISTS}
                    itemLabel="Watchlists"
                    isSelected={activeSideNav === NAV_ITEM_VALUE.WATCHLISTS}
                    icons={icons}
                    handleNavItemOnClick={handleWatchlistOnClick}
                    className={sideNavWatchlistExpand ? "tt-wl-expanded" : ""}
                />
            )}
            <Collapse in={sideNavWatchlistExpand}>
                {watchlists.map((wl, idx) => {
                    return (
                        <NavItem
                            key={wl.id}
                            itemValue={wl.id}
                            itemLabel={wl.name}
                            handleNavItemOnClick={handleWatchlistItemOnClick}
                            isSelected={activeWatchlist === wl.id}
                            isWatchlistItem
                            // whether it's the last 2 wl items and the wl has more than 2 items
                            isLastWatchlistItem={idx >= 2 && idx >= watchlists.length - 2}
                            watchlists={watchlists}
                            userInfo={userInfo}
                            setWatchlists={setWatchlists}
                            setActiveWatchlist={setActiveWatchlist}
                            onGoHome={onGoHome}
                            navigate={navigate}
                        />
                    );
                })}
            </Collapse>
        </List>
    );
}
