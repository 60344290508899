import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FEED_API_BASE } from "src/api";
import { Stories } from "../Stories";
import { fetchStories, buildFeedUrlParameters, getTopStories, LocalStorageKeys } from "src/helpers";
import { FilterObjectType } from "src/types";

export function PureFeed(props) {
    const { tickers, filter, blocked_websites, top_stories_only, enableFilters } = props;
    const [loading, setLoading] = useState(false);
    const [stories, setStories] = useState([]);
    const [lastId, setLastId] = useState(null);
    const [ended, setEnded] = useState(false);

    const buildFeedUrl = useCallback(
        (lastId) => {
            if (!tickers || !Array.isArray(tickers) || tickers.length === 0) {
                throw new Error("No ticker is set.");
            }

            const FIRST_TICKER_TYPE = typeof tickers[0];

            const expectedTypes = ["object", "string"];
            if (!expectedTypes.includes(FIRST_TICKER_TYPE)) {
                throw new Error("Ticker should be a string or an object.");
            }

            let t = tickers,
                useSourceTypes = true,
                f = filter;

            if (FIRST_TICKER_TYPE === "object") {
                // * tickers of watchlist pages is array of object
                // * tickers of other pages is array of string
                // * and in single ticker page, it's also an array of string, just having 1 ticker
                t = tickers.map((tickerObject) => tickerObject.ticker);
            }

            const params = buildFeedUrlParameters(t, f, enableFilters, {
                lastId,
                useSourceTypes,
                blocked_websites,
            });
            if (top_stories_only) {
                params.set("n", 301);
            }
            const url = `${FEED_API_BASE}?${params.toString()}`;

            return url;
        },
        [tickers, filter, enableFilters, blocked_websites, top_stories_only]
    );

    // * load news for the first time
    useEffect(() => {
        (async () => {
            setEnded(false);
            setLoading(true);
            try {
                const url = buildFeedUrl();
                const result = await fetchStories(url);
                if (top_stories_only) {
                    result.stories = getTopStories(result.stories);
                }
                setLastId(result?.last_id);
                setStories(
                    result.stories.filter(
                        (s) => !blocked_websites || !blocked_websites.includes(s.site)
                    )
                );
            } catch (err) {
                console.error("Feed useEffect err :>> ", err);
                window.Sentry?.captureException(err);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            setLastId(null);
            setStories([]);
        };
    }, [buildFeedUrl, filter, tickers, blocked_websites, top_stories_only]);

    // * load more news
    const loadMore = useCallback(async () => {
        if (loading || ended) return;
        setLoading(true);

        try {
            const feedUrl = buildFeedUrl(lastId);
            const result = await fetchStories(feedUrl);
            if (top_stories_only) {
                result.stories = getTopStories(result.stories);
            }
            if (!result.last_id) {
                setEnded(true);
                setLastId(null);
            } else {
                setLastId(result.last_id);
                setStories((stories) =>
                    stories
                        .concat(result.stories)
                        .filter((s) => !blocked_websites || !blocked_websites.includes(s.site))
                );
            }

            window.localStorage.setItem(
                LocalStorageKeys.LoadMoreStatus,
                JSON.stringify({ error_timestamp: 0 })
            );
        } catch (err) {
            console.error("Feed loadMore err :>> ", err);
            window.Sentry?.captureException(err);

            window.localStorage.setItem(
                LocalStorageKeys.LoadMoreStatus,
                JSON.stringify({ error_timestamp: Date.now() })
            );
        } finally {
            setLoading(false);
        }
    }, [loading, ended, buildFeedUrl, lastId, setStories, blocked_websites, top_stories_only]);

    return <Stories stories={stories} loading={loading} loadMore={loadMore} ended={ended} />;
}

PureFeed.propTypes = {
    tickers: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.arrayOf(PropTypes.string),
    ]),
    filter: PropTypes.exact(FilterObjectType),
    blocked_websites: PropTypes.arrayOf(PropTypes.string),
    top_stories_only: PropTypes.bool,
    enableFilters: PropTypes.bool,
};
