import React from "react";
import { Layout } from "./Layout";

export const withLayout = (Component) => {
    const WithLayoutHOC = () => {
        return (
            <Layout>
                <Component />
            </Layout>
        );
    };
    return WithLayoutHOC;
};
