import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Menu, MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import { updateWatchlists, fetchTicker } from "src/helpers";
import { CreateWatchlistModal } from "./CreateWatchlistModal";
import { UserInfoType } from "src/types";
import { TickerFilter } from "./Filter";
import { usePrevious } from "react-use";

const useStyles = makeStyles({
    TMContainer: {
        width: "980px",
        "@media screen and (max-width:1280px)": {
            width: "100%",
        },
        height: "36px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "25px",
        marginBottom: "25px",
    },
    TMTicker: {
        fontSize: "20px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        "& span": {
            marginRight: "6px",
            display: "flex",
            alignItems: "center",
        },
    },
    TMButton: {
        border: "2px solid #4482B2",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "203px",
        padding: "9px 16px",
        color: "#4482B2",
        fontSize: "13px",
        fontWeight: "700",
    },
    AddIcon: {
        marginRight: "8px",
    },
    WatchlistMenu: {
        zIndex: "1 !important", // !TODO: not best practice, fix issue no.491, refactor later
        "& .MuiPopover-paper": {
            top: "135px !important",
            width: "203px",
        },
        "& ul": {
            height: "320px", // * 万一有人创建了100个wl，再大的屏幕也装不下，所以干脆统一最大高度320px，反正有scrollbar
        },
    },
    CreateWatchlistIcon: {
        minWidth: 0,
    },
    CreateWatchlistText: {
        textAlign: "right",
    },
});

export function TickerManagement({
    currentTicker: currentTickerString,
    isAuth,
    userInfo,
    watchlists,
    setUpdatedWatchlists,
    currentTickerFilterKV,
    setCurrentTickerFilterKV,
}) {
    const classes = useStyles();
    const [popAnchorEl, setPopAnchorEl] = useState(null);
    const navigate = useNavigate();

    const [currentTickerObject, setCurrentTickerObject] = useState({});
    const previousTickerObject = usePrevious(currentTickerObject);

    useEffect(() => {
        if (previousTickerObject?.ticker !== currentTickerString) {
            fetchTicker(currentTickerString)
                .then((res) => {
                    setCurrentTickerObject(res || {});
                })
                .catch((err) => {
                    console.error(err);
                    window.Sentry?.captureException(err);
                });
        }
    }, [previousTickerObject, currentTickerString]);

    const handleButtonOnClick = useCallback(
        (evt) => {
            if (!isAuth) {
                navigate("/user/auth");
                return;
            }
            setPopAnchorEl(evt.currentTarget);
        },
        [navigate, isAuth]
    );

    const handleListOnClose = useCallback(() => {
        setPopAnchorEl(null);
    }, []);

    const addTickerToWl = useCallback(
        async (wl) => {
            const alreadyHasThisTicker = wl.tickers.some(
                (t) => t.ticker === currentTickerObject.ticker
            );
            if (alreadyHasThisTicker) {
                window.Swal.fire("Try again?", "The ticker is already in the watchlist", "error");
                return;
            }

            let success = false;
            let Item;

            try {
                const updatedWatchlists = cloneDeep(watchlists).map((watchlist) => {
                    if (watchlist.id === wl.id) {
                        watchlist.tickers.push(currentTickerObject);
                    }
                    return watchlist;
                });

                // * result has the updated userData, { watchlistsUpdated, Item }
                const result = await updateWatchlists({
                    userId: userInfo.id,
                    watchlists: updatedWatchlists,
                });

                if (!result.watchlistsUpdated) {
                    throw new Error("Failed to update watchlists");
                }
                Item = result.Item;
                success = true;
            } catch (err) {
                console.error(err);
            }

            const tickerSymbol = currentTickerObject.ticker.toUpperCase();

            if (success) {
                setUpdatedWatchlists(Item.watchlists); // update watchlists in redux store
                handleListOnClose();
                await window.Swal.fire({
                    text: `$${tickerSymbol} added to your watchlist`,
                    icon: "success",
                    timer: 1500,
                    timerProgressBar: true,
                });
            } else {
                window.Swal.fire(
                    "Try again?",
                    `Failed to add ${tickerSymbol} to watchlist`,
                    "error"
                );
            }
        },
        [currentTickerObject, handleListOnClose, setUpdatedWatchlists, userInfo, watchlists]
    );

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = useCallback(() => {
        setModalOpen((modalOpen) => !modalOpen);
    }, []);

    const handleCreateWatchlistButtonOnClick = useCallback(() => {
        if (isAuth) {
            toggleModal();
        } else {
            // go to login
            navigate("/user/auth");
        }
    }, [navigate, isAuth, toggleModal]);

    return (
        <Box className={classes.TMContainer}>
            <div className={classes.TMTicker}>
                <span>{currentTickerObject.company_name}</span>
                <span>
                    {currentTickerObject.company_name && (
                        <TickerFilter
                            currentTickerFilterKV={currentTickerFilterKV}
                            setCurrentTickerFilterKV={setCurrentTickerFilterKV}
                        />
                    )}
                </span>
            </div>
            <Button
                aria-controls="TM-wl-list"
                aria-haspopup="true"
                onClick={handleButtonOnClick}
                className={classes.TMButton}
            >
                <AddIcon className={classes.AddIcon} /> ADD TO WATCHLIST
            </Button>
            <Menu
                id="TM-wl-list"
                anchorEl={popAnchorEl}
                open={Boolean(popAnchorEl)}
                onClose={handleListOnClose}
                className={classes.WatchlistMenu}
            >
                {watchlists.map((wl) => {
                    return (
                        <MenuItem key={wl.id} onClick={() => addTickerToWl(wl)}>
                            <ListItemText primary={wl.name} />
                        </MenuItem>
                    );
                })}

                <MenuItem onClick={handleCreateWatchlistButtonOnClick}>
                    <ListItemIcon className={classes.CreateWatchlistIcon}>
                        <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={"Create a watchlist"}
                        className={classes.CreateWatchlistText}
                    />
                </MenuItem>
            </Menu>
            <CreateWatchlistModal
                modalOpen={modalOpen}
                isAuth={isAuth}
                watchlists={watchlists}
                setUpdatedWatchlists={setUpdatedWatchlists}
                toggleModal={toggleModal}
                userInfo={userInfo}
                currentTicker={currentTickerObject}
                closeWatchlistDropdown={handleListOnClose}
            />
        </Box>
    );
}

TickerManagement.propTypes = {
    currentTicker: PropTypes.string,
    isAuth: PropTypes.bool,
    watchlists: PropTypes.arrayOf(PropTypes.object),
    userInfo: UserInfoType,
    setUpdatedWatchlists: PropTypes.func,
    currentTickerFilterKV: PropTypes.object,
    setCurrentTickerFilterKV: PropTypes.func,
};
