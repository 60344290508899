import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FilterAlt as FilterAltIcon, Close as CloseIcon } from "@mui/icons-material";
import { ClickAwayListener, Box, Chip } from "@mui/material";
import { SX } from "./utils";
import { useMemoizedFn } from "ahooks";
import { FilterSection } from "./FilterSection";
import { isEqual, cloneDeep } from "lodash";
import { usePrevious } from "react-use";

export const TickerFilter = (props) => {
    const { currentTickerFilterKV, setCurrentTickerFilterKV } = props;
    const [filterOpen, setFilterOpen] = useState(false);

    const [internalFilterKV, setInternalFilterKV] = useState(currentTickerFilterKV);
    const previousInternalFilter = usePrevious(internalFilterKV);

    const handleOnClickAway = useMemoizedFn(() => {
        if (filterOpen) {
            setFilterOpen(false);
            if (!isEqual(previousInternalFilter, internalFilterKV)) {
                setCurrentTickerFilterKV(internalFilterKV);
            }
        }
    });

    const handleToggleFilterDropdown = useCallback(() => {
        setFilterOpen((filterOpen) => !filterOpen);
    }, []);

    const handleFilterCheckboxOnClick = useCallback((group, filterSectionItemValue, checked) => {
        const k = filterSectionItemValue;
        const v = checked;

        setInternalFilterKV((iFilter) => {
            const f = cloneDeep(iFilter);
            f[group][k] = v;
            return f;
        });
    }, []);

    return (
        <ClickAwayListener onClickAway={handleOnClickAway}>
            <Box sx={{ position: "relative" }}>
                <Chip
                    icon={<FilterAltIcon />}
                    key={"Filter"}
                    sx={SX.OperationChip}
                    label={"Filter"}
                    onClick={handleToggleFilterDropdown}
                />

                {filterOpen && (
                    <Box sx={SX.FilterDropdownContainer}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={SX.FilterDropdownPrimaryTitle}
                        >
                            <CloseIcon
                                onClick={handleToggleFilterDropdown}
                                style={{ cursor: "pointer" }}
                            />
                        </Box>

                        <Box width="100%" height="25px" flexShrink={0}></Box>
                        <FilterSection
                            internalFilter={internalFilterKV}
                            handleFilterCheckboxOnClick={handleFilterCheckboxOnClick}
                            groupValue={"storyTypes"}
                            groupLabel={"Story Types"}
                        />
                        <FilterSection
                            internalFilter={internalFilterKV}
                            handleFilterCheckboxOnClick={handleFilterCheckboxOnClick}
                            groupValue={"exclusions"}
                            groupLabel={"Exclusions"}
                        />
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    );
};

TickerFilter.propTypes = {
    currentTickerFilterKV: PropTypes.object,
    setCurrentTickerFilterKV: PropTypes.func,
};
