import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Link, IconButton, Button } from "@mui/material";
import { Close as CloseIcon, ExitToApp as ExitToAppOutlinedIcon } from "@mui/icons-material";
import { ReactComponent as LogoSvg } from "src/images/logo-full.svg";
import { useSelector, useDispatch } from "react-redux";
import "./Header.scss";
import * as appActions from "src/redux/app/app-actions";

export function Header({ setDisplaySidenav }) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const isAuth = useSelector((state) => state.app.isAuth);
    const username = useSelector((state) => state.app.userInfo?.username);

    const goToUserAuth = useCallback(() => {
        navigate("/user/auth");
    }, [navigate]);

    const goHome = useCallback(() => {
        navigate("/");
        dispatch(appActions.onGoHome());
    }, [dispatch, navigate]);

    const handleUserLogout = useCallback(() => {
        window.localStorage.removeItem("TickerTick.UserRaw");
        window.location.href = window.location.origin;
    }, []);

    const hideSidenav = useCallback(() => {
        if (setDisplaySidenav) {
            setDisplaySidenav(false);
        }
    }, [setDisplaySidenav]);

    return (
        <Box display="flex" flexDirection="column" className="Header-container">
            <Box display="flex" className="SideNav-Header-logo">
                <LogoSvg onClick={goHome} />
                <span>beta</span>
                <span></span>
                <IconButton size="small" onClick={hideSidenav}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box display="flex" alignItems="center" className="TickerTick_API_Banner">
                <Button>
                    <a
                        href="https://github.com/hczhu/TickerTick-API"
                        target="_blank"
                        rel="noreferrer"
                        className="SideNav-Info__Link"
                    >
                        Powered By TickerTick API
                    </a>
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="SideNav-Header-username"
            >
                {isAuth ? (
                    username
                ) : (
                    <Link color="inherit" underline="none" onClick={goToUserAuth}>
                        Login or Signup
                    </Link>
                )}
                {isAuth && <ExitToAppOutlinedIcon className="logout" onClick={handleUserLogout} />}
            </Box>
        </Box>
    );
}

Header.propTypes = {
    setDisplaySidenav: PropTypes.func,
};
