import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Search } from "src/components";

export function MainViewPlaceholder(props) {
    // * !ATTENTION: withSearch会显示Search，主要目的是用于mobile时toggle sidenav
    // * 而且此placeholder的目的是, 在没有内容时的占位，因此，search即使显示，也没有filter的功能，只能搜索和toggle sidenav
    // !TODO: mobile的时候，还有一些minor style issue，但不影响使用。
    // !TODO: 终极改造方案是，把search做成一个独立的组件，只接收props，目前很接近了，但没有完全实现，应该建一个issue
    const {
        children,
        withSearch,
        flexDirection = "row",
        justifyContent = "center",
        alignItems = "center",
        maxWidth = "1280px",
        wrapper,
        ...rest
    } = props;

    const Wrapper = useMemo(() => {
        return wrapper || React.Fragment;
    }, [wrapper]);

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection={flexDirection}
            justifyContent={justifyContent}
            alignItems={alignItems}
            maxWidth={maxWidth}
            {...rest}
        >
            <Wrapper>
                {withSearch && <Search />}
                {children}
            </Wrapper>
        </Box>
    );
}

MainViewPlaceholder.propTypes = {
    children: PropTypes.node,
    withSearch: PropTypes.bool,
    maxWidth: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    flexDirection: PropTypes.string,
    wrapper: PropTypes.elementType,
};
