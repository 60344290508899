import { createReducer } from "@reduxjs/toolkit";
import * as feedActions from "./feed-actions";

const initialState = {
    topStoriesTickers: null,
    recentIpoTickers: null,
    upcomingIpoTickers: null,
};

export const feedReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(feedActions.setTopStoriesTickers, (state, action) => {
            const { topStoriesTickers } = action.payload;
            state.topStoriesTickers = topStoriesTickers;
        })
        .addCase(feedActions.setStartupTickers, (state, action) => {
            const { startupTickers } = action.payload;
            state.startupTickers = startupTickers;
        })
        .addCase(feedActions.setRecentIpoTickers, (state, action) => {
            const { recentIpoTickers } = action.payload;
            state.recentIpoTickers = recentIpoTickers;
        })
        .addCase(feedActions.setUpcomingIpoTickers, (state, action) => {
            const { upcomingIpoTickers } = action.payload;
            state.upcomingIpoTickers = upcomingIpoTickers;
        });
});
