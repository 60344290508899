import { TICKER_SEARCH_API_BASE } from "src/api";
import { getUserDataById } from "./dynamo-helpers";
import { getAccessToken } from "./auth-helpers";
import { LocalStorageKeys, AuthProvider, CURRENT_FIREBASE_AUTH_PROVIDER } from "./const-helpers";
import decodeJwt from "jwt-decode";

export async function refreshPageAndRestoreUserState({ onLogin, setAppInitialized }) {
    try {
        const userRawString = window.localStorage.getItem(LocalStorageKeys.UserRaw);

        // it means user is never lgged in
        if (!userRawString) {
            setAppInitialized(true);
            return;
        }

        const userRaw = JSON.parse(userRawString);
        const { authProvider } = userRaw;

        let userInfo;

        if (authProvider === AuthProvider.COGNITO || !authProvider) {
            // * update accessToken and make sure there is IdToken in localStorage
            const accessToken = await getAccessToken();
            // * no accessToken, means not login yet
            if (accessToken === null) {
                setAppInitialized(true);
                return;
            }

            const decodedIdToken = decodeJwt(userRaw.IdToken);
            userInfo = {
                id: decodedIdToken.sub,
                email: decodedIdToken.email,
                username: decodedIdToken.email.split("@")[0],
            };
        } else if (CURRENT_FIREBASE_AUTH_PROVIDER.includes(authProvider)) {
            userInfo = {
                id: userRaw.id,
                email: userRaw.email,
                username: userRaw.username,
                jwt: userRaw.jwt,
            };
        }

        const { Item } = await getUserDataById(userInfo.id);

        onLogin({
            userRaw,
            userInfo,
            userStorage: Item,
        });
    } catch (err) {
        console.error("refreshPageAndRestoreUserState: >>", err);
        window.Sentry?.captureException(err);
        // ! TODO: go to login page ??
    }
}

export async function retriveTopStoriesTickers() {
    let TOP_STORIES_TICKERS = window.localStorage.getItem("TOP_STORIES_TICKERS");
    const json = JSON.parse(TOP_STORIES_TICKERS);
    const expiryInterval = 3600 * 1000; // One hour

    let topStoriesTickers = json?.topStoriesTickers;

    if (!TOP_STORIES_TICKERS || json?.expiry <= Date.now() || topStoriesTickers?.length === 0) {
        const req = await fetch(`${TICKER_SEARCH_API_BASE}?p=reddit_trending&n=30`);
        const result = await req.json();
        topStoriesTickers = result.tickers.map((t) => t.ticker);
        if (topStoriesTickers.length === 0) {
            // Something is wrong here. Let's use a static list and return.
            return ["aapl", "msft", "googl", "amzn", "tsla", "fb", "tsm", "nvda"];
        }
        TOP_STORIES_TICKERS = JSON.stringify({
            expiry: Date.now() + expiryInterval,
            topStoriesTickers,
        });
        window.localStorage.setItem("TOP_STORIES_TICKERS", TOP_STORIES_TICKERS);
        window.localStorage.setItem(
            "TOP_STORIES_TICKER_OBJECT_ARRAY",
            JSON.stringify(result.tickers)
        );
    }
    return topStoriesTickers;
}
