// side effects (epics)
import * as appActions from "./app-actions";
import { combineEpics, ofType } from "redux-observable";
import { mapTo, mergeAll, concatMap, switchMap } from "rxjs/operators";
import * as feedActions from "src/redux/feed/feed-actions";
import { retriveTopStoriesTickers, getStartupTickers, getIpoTickers } from "src/helpers";
import { forkJoin } from "rxjs";

const onLoginEpic = (action$) => {
    return action$.pipe(
        ofType(appActions.ON_LOGIN),
        concatMap((action) => {
            const { userStorage, userInfo, userRaw } = action.payload;

            const { watchlists } = userStorage;

            return [
                appActions.setAuth(),
                appActions.setWatchlists({ watchlists }),
                appActions.setUserInfo({ userInfo }),
                appActions.setUserRaw({ userRaw }),
                appActions.setActiveSideNav({ activeSideNav: "TOP_STORIES" }),
                appActions.setAppInitialized({ appInitialized: true }),
            ];
        })
    );
};

const onGoHomeEpic = (action$) => {
    return action$.pipe(
        ofType(appActions.ON_GO_HOME),
        mapTo([
            appActions.setActiveSideNav({ activeSideNav: "TOP_STORIES" }),
            appActions.setActiveWatchlist({ activeWatchlist: null }),
            appActions.setSidenavWatchlistExpand({ sideNavWatchlistExpand: false }),
        ]),
        mergeAll()
    );
};

const clearSideNavStateEpic = (action$) => {
    return action$.pipe(
        ofType(appActions.CLEAR_SIDENAV_STATE),
        mapTo([
            appActions.setActiveSideNav({ activeSideNav: null }),
            appActions.setSidenavWatchlistExpand({ sideNavWatchlistExpand: false }),
            appActions.setActiveWatchlist({ activeWatchlist: null }),
        ]),
        mergeAll()
    );
};

const appInitEpic = (action$) => {
    return action$.pipe(
        ofType(appActions.SET_APP_INITIALIZED),
        switchMap(() => {
            return forkJoin({
                topStoriesTickers: retriveTopStoriesTickers(),
                startupTickers: getStartupTickers(),
                ipoTickers: getIpoTickers(),
            });
        }),
        concatMap((data) => {
            const {
                topStoriesTickers,
                startupTickers,
                ipoTickers: { recentIpoTickers, upcomingIpoTickers },
            } = data;
            return [
                feedActions.setTopStoriesTickers({ topStoriesTickers }),
                feedActions.setStartupTickers({ startupTickers }),
                feedActions.setUpcomingIpoTickers({ upcomingIpoTickers }),
                feedActions.setRecentIpoTickers({ recentIpoTickers }),
            ];
        })
    );
};

export const appEpics = combineEpics(onLoginEpic, onGoHomeEpic, clearSideNavStateEpic, appInitEpic);
