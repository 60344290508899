export const GA_EVENT_CATEGORY = {
    USER: "User",
};

export const GA_EVENT_ACTION = {
    CLICK_SIDE_NAV: "click side nav",
    CLICK_WATCHLIST: "click watchlist",
};

export const GA_EVENT_LABEL = {
    TOP_STORIES: "top stories",
    RECENT_IPO: "recent ipo",
    UPCOMING_IPO: "upcoming ipo",
    WATCHLISTS: "watchlists",
};
