import React, { createContext, useContext, useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

const FeedContainerContext = createContext();

// * when a route matches the patterns, hide the block website option
const routePatterns = [
    { path: "/ticker/:tickerSymbol/feed" },
    { path: "/shared/watchlist/:sharedWatchlistId" },
];

export const FeedContainerContextProvider = ({ children }) => {
    const location = useLocation();
    const match = matchRoutes(routePatterns, location);

    const value = useMemo(() => {
        return {
            storyItemConfigs: {
                hideBlockWebsiteOption: match !== null,
            },
        };
    }, [match]);

    return <FeedContainerContext.Provider value={value}>{children}</FeedContainerContext.Provider>;
};

FeedContainerContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useFeedContainerContext = () => useContext(FeedContainerContext);
