import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FilterAlt as FilterAltIcon, Close as CloseIcon } from "@mui/icons-material";
import { ClickAwayListener, Box, Chip } from "@mui/material";
import { SX } from "./utils";
import { useMemoizedFn } from "ahooks";
import { FilterSection } from "./FilterSection";
import { updateWatchlists, convertFilter2KV, convertKV2Filter } from "src/helpers";
import { isEqual, cloneDeep } from "lodash";

export const WatchlistFilter = ({
    watchlists,
    currentWatchlist,
    userInfo,
    setWatchlists,
    sharedWatchlistId,
}) => {
    const [filterOpen, setFilterOpen] = useState(false);
    // Format:  kv format, i.e., { exclusions: { ugc: true, sec_filing: true} }
    const [internalFilter, setInternalFilter] = useState();

    useEffect(() => {
        setInternalFilter(convertFilter2KV(currentWatchlist?.filters));
    }, [currentWatchlist]);

    const handleOnClickAway = useMemoizedFn(() => {
        if (filterOpen) {
            setFilterOpen(false);
            if (!isEqual(convertFilter2KV(currentWatchlist?.filters), internalFilter)) {
                commitUpdateFilter();
            }
        }
    });

    const commitUpdateFilter = useMemoizedFn(async () => {
        if (sharedWatchlistId) {
            //TODO: do something local, no need to update the shared watchlist
            return;
        }

        try {
            const newWls = watchlists.map((wl) => {
                if (wl.id === currentWatchlist.id) {
                    wl = { ...wl, filters: convertKV2Filter(internalFilter) };
                }
                return wl;
            });
            // * update watchlist filter in redux
            setWatchlists(newWls);
            // * update watchlist in dynamodb
            const result = await updateWatchlists({
                userId: userInfo.id,
                watchlists: newWls,
            });
            if (!result.watchlistsUpdated) {
                throw new Error("Failed to update watchlists");
            }
        } catch (err) {
            console.error(err);
            window.Sentry?.captureException(err);
            // * restore to original watchlists
            setWatchlists(watchlists);
        }
    });

    const handleToggleFilterDropdown = useMemoizedFn(() => {
        if (filterOpen) {
            setFilterOpen(false);
            // if (!isEqual(convertFilter2KV(filters), internalFilter)) {
            //     commitUpdateFilter();
            // }
        } else {
            setFilterOpen(true);
        }
    });

    const handleFilterCheckboxOnClick = useCallback((group, filterSectionItemValue, checked) => {
        const k = filterSectionItemValue;
        const v = checked;

        setInternalFilter((iFilter) => {
            const f = cloneDeep(iFilter);
            f[group][k] = v;
            return f;
        });
    }, []);

    return (
        <ClickAwayListener onClickAway={handleOnClickAway}>
            <Box sx={{ position: "relative" }}>
                <Chip
                    icon={<FilterAltIcon />}
                    key={"Filter"}
                    sx={SX.OperationChip}
                    label={"Filter"}
                    onClick={handleToggleFilterDropdown}
                />

                {filterOpen && (
                    <Box sx={SX.FilterDropdownContainer}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={SX.FilterDropdownPrimaryTitle}
                        >
                            <CloseIcon
                                onClick={handleToggleFilterDropdown}
                                style={{ cursor: "pointer" }}
                            />
                        </Box>

                        <Box width="100%" height="25px" flexShrink={0}></Box>
                        <FilterSection
                            internalFilter={internalFilter}
                            handleFilterCheckboxOnClick={handleFilterCheckboxOnClick}
                            groupValue={"storyTypes"}
                            groupLabel={"Story Types"}
                        />
                        <FilterSection
                            internalFilter={internalFilter}
                            handleFilterCheckboxOnClick={handleFilterCheckboxOnClick}
                            groupValue={"exclusions"}
                            groupLabel={"Exclusions"}
                        />
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    );
};

WatchlistFilter.propTypes = {
    watchlists: PropTypes.array,
    currentWatchlist: PropTypes.object,
    userInfo: PropTypes.object,
    setWatchlists: PropTypes.func,
    sharedWatchlistId: PropTypes.string,
};
