import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, ListItemText, Link } from "@mui/material";
import MaybeAvatar from "./MaybeAvatar";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import "./SimilarStories.scss";
import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
} from "@mui/icons-material";

const renderSimilarStory = (s) => {
    return (
        <Box
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
            key={s.id}
            id={s.id}
            className="tt_similar_story"
        >
            <MaybeAvatar
                variant="square"
                alt={s.site}
                title={s.site}
                src={s.favicon_url}
                className="tt_similar_story_avatar"
            >
                {
                    s.site
                        .substr(s.site.search(/[^.]*\.[^.]*$/), 1)
                        .toUpperCase() /** 如果没有favicon，取site的首字母 */
                }
            </MaybeAvatar>

            <ListItemText
                className="tt_simiar_story_title"
                primary={
                    <Link
                        href={s.url}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                    >
                        {s.title}
                    </Link>
                }
            />
        </Box>
    );
};

const ExpandIcon = (props) => {
    // eslint-disable-next-line
    return props.isActive ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
};
export function SimilarStories({ stories }) {
    const [collapsed, setCollapsed] = useState(true);
    const handleCollapseOnChange = useCallback(() => setCollapsed((c) => !c), []);
    const maxRenderedSimilarStories = 3;
    return (
        <Box component="div" display="flex" flexDirection="column" alignItems="flex-start">
            {stories.slice(0, maxRenderedSimilarStories).map(renderSimilarStory)}
            {stories.length > maxRenderedSimilarStories && (
                <Collapse
                    accordion={true}
                    className="similar-stories-collapse"
                    onChange={handleCollapseOnChange}
                    expandIcon={ExpandIcon}
                >
                    <Panel
                        header={collapsed ? "Show more" : "Show less"}
                        headerClass="similar-stories-collapse-header"
                    >
                        {stories.slice(maxRenderedSimilarStories).map(renderSimilarStory)}
                    </Panel>
                </Collapse>
            )}
        </Box>
    );
}

SimilarStories.propTypes = {
    stories: PropTypes.arrayOf(PropTypes.object).isRequired,
};
