import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export function Tags({ tags }) {
    const navigate = useNavigate();

    const goToTickerFeed = useCallback(
        (evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            const ticker = evt.target.dataset.ticker;
            navigate(`/ticker/${ticker}/feed`);
        },
        [navigate]
    );
    return (
        <div className="tt_feed_tags">
            {tags.map((tag, i) => (
                <button
                    data-ticker={tag}
                    key={`${tag}-${i}`}
                    className="tt_feed_tag"
                    onClick={goToTickerFeed}
                >
                    {`$${tag}`}
                </button>
            ))}
        </div>
    );
}

Tags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};
