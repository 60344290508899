import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Modal, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { updateWatchlists, kDefaultFilter } from "src/helpers";
import { TickerObjectType, UserInfoType } from "src/types";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles(() => {
    return {
        modal: {
            transform: "translate(-50%, -50%)",

            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px 24px 8px",

            position: "absolute",
            top: "50%",
            left: "50%",
            width: "316px",
            height: "198px",

            backgroundColor: "#FFFFFF",
            boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
            borderRadius: "2px",
        },
        title: {
            margin: "0px",
            height: "30px",
        },
        input: {
            marginTop: "12px",
            width: "100%",
        },
        spacer: {
            width: "16px",
        },
        button: {
            margin: "0px",
            padding: "0px",
            height: "16px",
        },
    };
});

export const CreateWatchlistModal = (props) => {
    const {
        modalOpen,
        toggleModal,
        isAuth,
        watchlists,
        setUpdatedWatchlists,
        userInfo,
        currentTicker,
        closeWatchlistDropdown,
    } = props;
    const classes = useStyles();

    const [watchlistName, setWatchlistName] = useState("");

    const handleInputOnChange = useCallback(
        ({ target: { value } }) => {
            setWatchlistName(value.trim());
        },
        [setWatchlistName]
    );

    const handleOnCreateWatchlist = useCallback(async () => {
        if (!watchlistName || !isAuth) {
            window.Swal.fire("Try again?", "Watchlist Name is missing", "error");
            return;
        }

        const findAlgo = (wl) => wl.name === watchlistName;
        if (watchlists.findIndex(findAlgo) > -1) {
            window.Swal.fire("Try again?", "Watchlist Name is duplicated", "error");
            return;
        }

        try {
            const updatedWatchlists = [
                ...watchlists,
                {
                    id: uuidv4(),
                    name: watchlistName,
                    tickers: [currentTicker],
                    filters: kDefaultFilter(),
                    created_at: Date.now(),
                    owner: {
                        email: userInfo.email,
                    },
                },
            ];
            const result = await updateWatchlists({
                userId: userInfo.id,
                watchlists: updatedWatchlists,
            });
            if (!result.watchlistsUpdated) {
                throw new Error("Failed to update watchlists");
            }
            setUpdatedWatchlists(result.Item.watchlists);

            toggleModal();
            closeWatchlistDropdown();
        } catch (err) {
            console.error("Footer err :>>", err);
            window.Sentry?.captureException(err);
        }
    }, [
        closeWatchlistDropdown,
        currentTicker,
        isAuth,
        setUpdatedWatchlists,
        toggleModal,
        userInfo,
        watchlistName,
        watchlists,
    ]);

    return (
        <Modal open={modalOpen} onClose={toggleModal}>
            <div className={classes.modal}>
                <h2 className={classes.title}>Create Watchlist</h2>
                <TextField
                    className={classes.input}
                    autoFocus
                    required
                    label="Name"
                    variant="outlined"
                    onChange={handleInputOnChange}
                />
                <Box
                    component="div"
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    marginTop="42px"
                >
                    <Button className={classes.button} onClick={toggleModal}>
                        CANCEL
                    </Button>
                    <Box component="span" className={classes.spacer}></Box>
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={handleOnCreateWatchlist}
                    >
                        CREATE
                    </Button>
                </Box>
            </div>
        </Modal>
    );
};

CreateWatchlistModal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    isAuth: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    watchlists: PropTypes.arrayOf(PropTypes.object),
    setUpdatedWatchlists: PropTypes.func.isRequired,
    userInfo: UserInfoType,
    currentTicker: PropTypes.exact(TickerObjectType),
    closeWatchlistDropdown: PropTypes.func.isRequired,
};
