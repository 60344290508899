import { createAction } from "@reduxjs/toolkit";

export const SET_APP_INITIALIZED = "APP.SET_APP_INITIALIZED";
export const setAppInitialized = createAction(SET_APP_INITIALIZED);

export const ON_LOGIN = "APP.ON_LOGIN";
export const onLogin = createAction(ON_LOGIN);

export const SET_USER_INFO = "APP.SET_USER_INFO";
export const setUserInfo = createAction(SET_USER_INFO);

export const SET_USER_RAW = "APP.SET_USER_RAW";
export const setUserRaw = createAction(SET_USER_RAW);

export const SET_AUTH = "APP.SET_AUTH";
export const setAuth = createAction(SET_AUTH);

export const SET_ACTIVE_SIDENAV = "APP.SET_ACTIVE_SIDENAV";
export const setActiveSideNav = createAction(SET_ACTIVE_SIDENAV);

export const SET_WATCHLISTS = "APP.SET_WATCHLISTS";
export const setWatchlists = createAction(SET_WATCHLISTS);

export const SET_ACTIVE_WATCHLIST = "APP.SET_ACTIVE_WATCHLIST";
export const setActiveWatchlist = createAction(SET_ACTIVE_WATCHLIST);

export const SET_SIDENAV_WATCHLIST_EXPAND = "APP.SET_SIDENAV_WATCHLIST_EXPAND";
export const setSidenavWatchlistExpand = createAction(SET_SIDENAV_WATCHLIST_EXPAND);

export const ON_GO_HOME = "APP.ON_GO_HOME";
export const onGoHome = createAction(ON_GO_HOME);

export const CLEAR_SIDENAV_STATE = "APP.CLEAR_SIDENAV_STATE";
export const clearSideNavState = createAction(CLEAR_SIDENAV_STATE);
