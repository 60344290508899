import { initializeApp } from "firebase/app";
import {
    getAuth,
    TwitterAuthProvider,
    GoogleAuthProvider,
    // FacebookAuthProvider,
    GithubAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile as updateFirebaseUserProfile,
    signInWithRedirect,
    getRedirectResult,
} from "firebase/auth";
import { LAMBDA_BASE_URL } from "src/api";

// import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: "AIzaSyAJKA-FzB0SptY4o-BZFG3-APhs-rc036s",
    authDomain: "ticker-tick.firebaseapp.com",
    projectId: "ticker-tick",
    storageBucket: "ticker-tick.appspot.com",
    messagingSenderId: "623550876101",
    appId: "1:623550876101:web:366cfe910cd2252b53a3ac",
    // measurementId: "G-4PPGSXWK1Y"
};

export const firebaseApp = initializeApp(firebaseConfig);
// export const firestore = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const twitterAuthProvider = new TwitterAuthProvider();
export const googleAuthProvider = new GoogleAuthProvider();
// export const facebookAuthProvider = new FacebookAuthProvider();
export const githubAuthProvider = new GithubAuthProvider();

export {
    // signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithRedirect,
    getRedirectResult,
    updateFirebaseUserProfile,
};

export const clearTmpData = () => {
    window.localStorage.removeItem("isSocialLoginOngoing");
    window.localStorage.removeItem("SocialLoginAuthProvider");
};

export const setTmpData = (provider) => {
    if (provider) {
        window.localStorage.setItem("isSocialLoginOngoing", 1);
        window.localStorage.setItem("SocialLoginAuthProvider", provider);
    }
};

export const getUserRawString = (result, authProvider) => {
    return JSON.stringify({
        authProvider,
        email: result.user.email || `${result.user.uid}@firebase.com`,
        id: result.user.uid,
        jwt: result._tokenResponse.idToken,
        username:
            result._tokenResponse.screenName ||
            result.user.displayName?.replace(" ", "") ||
            result.user.email?.split("@")[0],
        avatar: result.user.photoURL,
        phone: result.user.phoneNumber,
    });
};

export const saveAuthResultToLocalStorage = (result, userRawString) => {
    window.localStorage.setItem("TickerTick.SocialLoginRaw", JSON.stringify(result));
    window.localStorage.setItem("TickerTick.UserRaw", userRawString);
};

export const saveUserDataToLocalStorage = (result) => {
    window.localStorage.setItem("TickerTick.UserStorage", JSON.stringify(result.data.userStorage));
};

export const onFirebaseSuccess = (userRawString) => {
    return fetch(`${LAMBDA_BASE_URL}/firebase`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: userRawString,
    });
};

export const execSignIn = async (provider) => {
    try {
        const result = await signInWithPopup(firebaseAuth, provider);
        if (!result) return;

        const authProvider = window.localStorage.getItem("SocialLoginAuthProvider");
        const userRawString = getUserRawString(result, authProvider);

        clearTmpData(); // clear isSocialLoginOngoing and SocialLoginAuthProvider

        onFirebaseSuccess(userRawString)
            .then((res) => res.json())
            .then((result2) => {
                // !Attention: clear all localStorage
                // ! so that old data does not  interfere the new feauture.
                window.localStorage.clear();
                saveAuthResultToLocalStorage(result, userRawString);
                saveUserDataToLocalStorage(result2);
                window.Swal.close();
                window.location.href = "/";
            });
    } catch (err) {
        console.error("err :>> ", err);
        clearTmpData();
    }
};
