import React from "react";
import PropTypes from "prop-types";
import { Box, Chip } from "@mui/material";
import { Share as ShareIcon } from "@mui/icons-material";
import { SX } from "./utils";
import { shareWatchlist } from "src/helpers";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMemoizedFn } from "ahooks";
import { FilterObjectType } from "src/types";
import { WatchlistFilter } from "../Filter";

export function WatchlistOperations(props) {
    const { tickers, userId, filters, name, blocked_websites, setWatchlists } = props;

    const { watchlistId, sharedWatchlistId } = useParams();

    const currentWatchlistId = watchlistId || sharedWatchlistId;

    const currentWatchlists = useSelector((state) => state.app.watchlists);
    const currentWatchlist = currentWatchlists.find((wl) => wl.id === currentWatchlistId);
    const userInfo = useSelector((state) => state.app.userInfo);

    //* 只有watchlist component会传handleOnDelete，所以，这里一定是已经登录，并且此wl有至少1个ticker

    // !ATTENTION: please test the case when the wl is a shared wl
    const handleShareOnClick = useMemoizedFn(async () => {
        try {
            const { watchlistShared, url } = await shareWatchlist({
                userId,
                name,
                tickers,
                prefix: window.location.origin,
                filters,
                blocked_websites,
            });

            if (!watchlistShared) {
                throw new Error("Failed to share the watchlist");
            }
            const { isConfirmed } = await window.Swal.fire({
                title: "Successfully shared the watchlist",
                icon: "success",
                html: url,
                showCloseButton: true,
                focusConfirm: false,
                confirmButtonText: "Copy url to clipboard",
            });
            if (isConfirmed) {
                const url = document.querySelector(".swal2-html-container").innerText;
                await navigator.clipboard.writeText(url);
                window.location.reload();
            }
        } catch (err) {
            console.error("err :>> ", err);
            // ! TODO: process error and do NOT use sweetalert2, since the modal has larger z-index
        }
    });

    return (
        <Box
            sx={{
                display: "flex",
                paddingBottom: "5px",
            }}
        >
            <WatchlistFilter
                watchlists={currentWatchlists}
                currentWatchlist={currentWatchlist}
                sharedWatchlistId={sharedWatchlistId}
                userInfo={userInfo}
                setWatchlists={setWatchlists}
            />
            {!sharedWatchlistId && (
                /** shared wl cannot be shared again */
                <Chip
                    icon={<ShareIcon />}
                    key={"Share"}
                    sx={SX.OperationChip}
                    label={"Share"}
                    onClick={handleShareOnClick}
                />
            )}
        </Box>
    );
}

WatchlistOperations.propTypes = {
    tickers: PropTypes.arrayOf(PropTypes.object),
    userId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired, // !TODO: what's this ??
    filters: PropTypes.exact(FilterObjectType),
    blocked_websites: PropTypes.arrayOf(PropTypes.string),
    setWatchlists: PropTypes.func,
};
