import React, { useMemo, useCallback, useState } from "react";
import { Box, ListItemAvatar, Link, ListItemText, Button } from "@mui/material";
import PropTypes from "prop-types";
import MaybeAvatar from "./MaybeAvatar";
import "./StoryItem.scss";
import { formatDistanceToNow, format } from "date-fns";
import { Tags } from "./Tags";
import { StoryItemActions } from "./StoryItemActions";
import { SimilarStories } from "./SimilarStories";
import { isBrowser } from "react-device-detect";
import * as userActions from "src/redux/user/user-actions";
import { useDispatch } from "react-redux";
import TwitterIcon from "@mui/icons-material/Twitter";
import { buildTwitterUrl, buildUrlWithUtmParams } from "src/helpers";
import { UserInfoType } from "src/types";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import { Heex } from "@heex/react";

export function StoryItem(props) {
    const dispatch = useDispatch();
    const {
        favicon_url,
        id: storyId,
        site,
        tags,
        time,
        title,
        url,
        isFirst,
        similar_stories_full,
        userInfo,
        description,
    } = props;

    const [display, setDisplay] = useState("flex");
    const hideStoryItem = useCallback(() => setDisplay("none"), []);
    const [commentOpen, setCommentOpen] = useState(false);

    const urlWithTickerTickUtmParams = useMemo(() => {
        const utmParams = {
            utm_source: "tickertick.com",
            utm_campaign: "TickerTick",
            utm_medium: "website",
        };

        return buildUrlWithUtmParams(url, utmParams);
    }, [url]);

    const borderTop = useMemo(() => {
        return isFirst ? "none" : "1px solid #e5e5e5";
    }, [isFirst]);

    const formattedTimeDistance = useMemo(() => {
        let t = time;
        const now = Date.now();
        const min_time_diff = 5 * 60 * 1000; // 5 minutes
        if (now - time < min_time_diff) {
            t = now - min_time_diff;
        }

        return formatDistanceToNow(t);
    }, [time]);

    const handleStoryLinkOnClick = useCallback(() => {
        try {
            dispatch(
                userActions.onLoggingUserBehavior({
                    storyId,
                    actionType: "click",
                    actionValue: null,
                    tags,
                })
            );
        } catch (err) {
            console.error("handleStoryLinkOnClick err: ", err);
        }
    }, [dispatch, storyId, tags]);

    const handeDislikeReasonOnClick = useCallback(
        (evt) => {
            try {
                const reason = evt.target.getAttribute("data-reason");

                // 只有登录用户才能block site，guest仅仅记录一下
                if (reason === "block_site" && userInfo?.id) {
                    dispatch(userActions.onUpdateBlocklists({ site }));
                } else {
                    dispatch(
                        userActions.onLoggingUserBehavior({
                            storyId,
                            actionType: "dislike",
                            actionValue: reason,
                            tags,
                        })
                    );

                    // * hide one item
                    hideStoryItem();
                }
            } catch (err) {
                console.error("handeDislikeReasonOnClick err: ", err);
            }
        },
        [dispatch, hideStoryItem, storyId, site, tags, userInfo?.id]
    );

    const tooltip = useMemo(() => {
        return isBrowser
            ? description && description.length > 1000
                ? `${description.slice(0, 1000)}...`
                : description
            : null;
    }, [description]);

    const toggleCommentSection = useCallback(() => {
        setCommentOpen((open) => !open);
    }, []);

    return (
        <Box
            id={storyId}
            display={display}
            width={"100%"}
            height={"auto"}
            borderTop={borderTop}
            className="tt_feed"
        >
            <ListItemAvatar className="tt_feed_avatar">
                <MaybeAvatar variant="square" alt={site} title={site} src={favicon_url}>
                    {
                        site
                            .substr(site.search(/[^.]*\.[^.]*$/), 1)
                            .toUpperCase() /** 如果没有favicon，取site的首字母 */
                    }
                </MaybeAvatar>
            </ListItemAvatar>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <span className="tt_feed_src">
                    {site.toUpperCase()} • {formattedTimeDistance} ago -&nbsp;
                    {format(time, "MMM dd yyyy")}
                </span>
                <ListItemText
                    className="tt_feed_title"
                    primary={
                        <Link
                            href={urlWithTickerTickUtmParams}
                            target="_blank"
                            rel="noreferrer"
                            onClick={handleStoryLinkOnClick}
                            data-balloon-length="fit"
                            aria-label={tooltip}
                            data-balloon-pos="down"
                            data-balloon-blunt
                            sx={{ textDecoration: "none" }}
                        >
                            {title}
                        </Link>
                    }
                />
                {similar_stories_full && <SimilarStories stories={similar_stories_full} />}
                <div className="tt_feed_footer">
                    <Tags tags={tags} />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Link
                            href={buildTwitterUrl(title, urlWithTickerTickUtmParams, tags)}
                            target="_blank"
                            sx={{ display: "flex", alignItems: "flex-end" }}
                        >
                            <TwitterIcon />
                        </Link>
                        <Button onClick={toggleCommentSection}>
                            <MarkUnreadChatAltOutlinedIcon />
                        </Button>
                        <StoryItemActions handeDislikeReasonOnClick={handeDislikeReasonOnClick} />
                    </Box>
                </div>
                {commentOpen && (
                    <Box sx={{ margin: "16px 0" }}>
                        <Heex
                            options={{
                                apiBaseUrl: "https://api.heex.dev/api/v1",
                                clientName: "heex-ticker-tick",
                                clientId: "aGVleC10aWNrZXItdGljawo=",
                                auth: { use: "anonymous" },
                            }}
                            pageId={storyId}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

StoryItem.propTypes = {
    favicon_url: PropTypes.string,
    id: PropTypes.string,
    site: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    time: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    isFirst: PropTypes.bool,
    similar_stories_full: PropTypes.arrayOf(PropTypes.object),
    userInfo: UserInfoType,
    description: PropTypes.string,
};

// {
//     "id": "1009861746871168654",
// eslint-disable-next-line
//     "title": "Windows 11 is making it easier to share your screen in Teams and other video calls",
//     "url": "https://www.theverge.com/2021/11/10/22774730/microsoft-windows-11-teams-integration-sharing-features",
//     "site": "theverge.com",
//     "time": 1636568858000,
//     "favicon_url": "https://static.tickertick.com/website_icons/theverge.com.ico",
//     "tags": [
//       "msft"
//     ],
// eslint-disable-next-line
//     "description": "Microsoft Teams share buttons will appear in Windows 11 soon. | Image: Microsoft Microsoft is adding a useful new Microsoft Teams integration into Windows 11 soon. The software maker has started testing a feature in Windows 11 that will allow"
//   }
