import React from "react";
import { Box, Container } from "@mui/material";
import PropTypes from "prop-types";
import { FeedContainerContextProvider } from "./FeedContainerContext";

export const FeedContainer = ({ children }) => {
    return (
        <FeedContainerContextProvider>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "1280px",
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    bgcolor: "#fbfcfd",
                    borderLeft: "1px solid #e5e5e5",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "980px",
                        width: "100%",
                    }}
                >
                    {children}
                </Box>
            </Container>
        </FeedContainerContextProvider>
    );
};

FeedContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

FeedContainer.displayName = "FeedContainer";
