import React from "react";
import PropTypes from "prop-types";
import { Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SaveSharedWatchlist } from "./SaveSharedWatchlist";

const useStyles = makeStyles({
    TickerChipsContainer: {
        width: "100%",
        "@media screen and (max-width:1280px)": {
            width: "100%",
        },
        display: "flex",
        flexDirection: "column",
    },
    ChipContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
});

const chipsx = {
    marginBottom: "5px",
    marginRight: "10px",
    backgroundColor: "#E3F2FD",
    color: "#4482B2",
    "& > svg > path": {
        color: "#4482B2",
        opacity: 0.34,
    },
};

export function TickerChips({ tickers, handleOnDelete, handleOnClick }) {
    const classes = useStyles();

    if (!tickers) {
        return null;
    }

    if (handleOnDelete && handleOnClick) {
        return null;
    }
    if (!handleOnDelete && !handleOnClick) {
        throw new Error("Ticker Chips Component needs an event cb");
    }

    return (
        <Box className={classes.TickerChipsContainer}>
            {handleOnDelete && (
                <Box className={classes.ChipContainer}>
                    {tickers.map((t) => {
                        return (
                            <Chip
                                key={t.ticker || t}
                                label={t.ticker?.toUpperCase() || t.toUpperCase()}
                                onDelete={() => handleOnDelete(t)}
                                sx={chipsx}
                            />
                        );
                    })}
                </Box>
            )}

            {handleOnClick && (
                <>
                    {window.location.pathname.startsWith("/shared/watchlist/") && (
                        <SaveSharedWatchlist />
                    )}
                    <Box className={classes.ChipContainer}>
                        {tickers.map((t) => {
                            return (
                                <Chip
                                    key={t.ticker || t}
                                    label={t.ticker?.toUpperCase() || t.toUpperCase()}
                                    onClick={() => handleOnClick(t.ticker || t)}
                                    sx={chipsx}
                                />
                            );
                        })}
                    </Box>
                </>
            )}
        </Box>
    );
}

TickerChips.propTypes = {
    tickers: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.arrayOf(PropTypes.string),
    ]),
    handleOnDelete: PropTypes.func,
    handleOnClick: PropTypes.func,
};
