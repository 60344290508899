import { createAction } from "@reduxjs/toolkit";

export const SET_TOP_STORIES_TICKERS = "FEED.SET_TOP_STORIES_TICKERS";
export const setTopStoriesTickers = createAction(SET_TOP_STORIES_TICKERS);

export const SET_STARTUP_TICKERS = "FEED.SET_STARTUP_TICKERS";
export const setStartupTickers = createAction(SET_STARTUP_TICKERS);

export const SET_RECENT_IPO_TICKERS = "FEED.SET_RECENT_IPO_TICKERS";
export const setRecentIpoTickers = createAction(SET_RECENT_IPO_TICKERS);

export const SET_UPCOMING_IPO_TICKERS = "FEED.SET_UPCOMING_IPO_TICKERS";
export const setUpcomingIpoTickers = createAction(SET_UPCOMING_IPO_TICKERS);
