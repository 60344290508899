import * as React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "normalize.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";
import Swal from "sweetalert2/src/sweetalert2.js";
import "@heex/react/index.css";

if (window.location.origin.includes("tickertick.com")) {
    Sentry.init({
        dsn: "https://8fc709884c51485b8ca0f87a84010a3c@o779376.ingest.sentry.io/5798064",
        integrations: [new Integrations.BrowserTracing()],
        environment: window.location.origin,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    window.Sentry = Sentry;
}

const queryClient = new QueryClient();

window.Swal = Swal;

ReactDOM.render(
    <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Sentry.ErrorBoundary>
    </Provider>,
    document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
