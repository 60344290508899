import { createReducer } from "@reduxjs/toolkit";
import * as appActions from "./app-actions";

const initialState = {
    appInitialized: false,
    activeSideNav: "TOP_STORIES",
    sideNavWatchlistExpand: false,
    activeWatchlist: null,
    userRaw: null,
    userInfo: null,
    watchlists: [],
    isAuth: false,
};

export const appReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(appActions.setAuth, (state) => {
            state.isAuth = true;
        })
        .addCase(appActions.setUserInfo, (state, action) => {
            const { userInfo } = action.payload;
            state.userInfo = userInfo;
        })
        .addCase(appActions.setUserRaw, (state, action) => {
            const { userRaw } = action.payload;
            state.userRaw = userRaw;
        })
        .addCase(appActions.setActiveSideNav, (state, action) => {
            const { activeSideNav } = action.payload;
            state.activeSideNav = activeSideNav;
        })
        .addCase(appActions.setWatchlists, (state, action) => {
            const { watchlists } = action.payload;
            state.watchlists = watchlists;
        })
        .addCase(appActions.setActiveWatchlist, (state, action) => {
            const { activeWatchlist } = action.payload;
            state.activeWatchlist = activeWatchlist;
        })
        .addCase(appActions.setSidenavWatchlistExpand, (state, action) => {
            const { sideNavWatchlistExpand } = action.payload;
            state.sideNavWatchlistExpand = sideNavWatchlistExpand;
        })
        .addCase(appActions.setAppInitialized, (state, action) => {
            const { appInitialized } = action.payload;
            state.appInitialized = appInitialized;
        });
});
