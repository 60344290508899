import { defaultStartupTickers } from "./const-helpers";
import { TICKER_SEARCH_API_BASE } from "src/api";

export async function getStartupTickers() {
    let STARTUP_TICKERS = window.localStorage.getItem("STARTUP_TICKERS");
    const json = JSON.parse(STARTUP_TICKERS);

    let expiryInterval = 0;
    const normalExpiryInterval = 1 * 24 * 3600 * 1000;
    const shortExpiryInterval = 1800 * 1000;

    let startupTickers = json?.STARTUP_TICKERS;

    if (!STARTUP_TICKERS || json?.expiry <= Date.now()) {
        try {
            const response = await fetch(`${TICKER_SEARCH_API_BASE}?p=.&n=50`);

            const result = await response.json();
            startupTickers = result.tickers.map((t) => t.ticker);

            const blockedTickers = new Set([".reddit"]);
            startupTickers = startupTickers.filter((t) => !blockedTickers.has(t.toLowerCase()));

            expiryInterval = normalExpiryInterval;
            if (startupTickers.length === 0) {
                // It's likely the backend has a transient issue.
                expiryInterval = shortExpiryInterval;
                startupTickers = defaultStartupTickers;
            }

            STARTUP_TICKERS = JSON.stringify({
                expiry: Date.now() + expiryInterval,
                STARTUP_TICKERS: startupTickers,
            });

            window.localStorage.setItem("STARTUP_TICKERS", STARTUP_TICKERS);
        } catch (e) {
            console.error(e);
        }
    }

    return startupTickers;
}
