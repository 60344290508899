import isEmail from "validator/lib/isEmail";
import { LAMBDA_BASE_URL } from "src/api";
import decodeJwt from "jwt-decode";
import { LocalStorageKeys, CURRENT_FIREBASE_AUTH_PROVIDER } from "./const-helpers";

/**
 *
 * @param {object} inputs
 * @returns {true | string}
 */
export const validateInputs = ({ email, password }) => {
    if (!email || !password) return "All fields are required";
    if (!isEmail(email)) return "Invalid email!";
    return true;
};

/**
 * !Attention: cognito login
 */
export async function login({ email, password }) {
    const response = await fetch(`${LAMBDA_BASE_URL}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({ email, password }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error(data.error);
    }

    const userRaw = data.userRaw.AuthenticationResult;

    const { IdToken } = userRaw;
    const decoded = decodeJwt(IdToken);

    const user_id = decoded.sub; // * like: 8b793f8e-f015-4f7e-be17-5fc213ab9918

    if (user_id !== data.userStorage.Item.user_id) {
        throw new Error("User does not match");
    }

    // update local storage
    window.localStorage.setItem(LocalStorageKeys.UserRaw, JSON.stringify(userRaw));

    return {
        userRaw,
        userInfo: {
            id: user_id,
            email: decoded.email,
            username: decoded.email.split("@")[0],
        },
        userStorage: data.userStorage.Item,
    };
}

// * id token decoded result:
// {
//     "sub": "cc714046-cb70-4ac8-babc-07446ef2d9a3",
//     "aud": "7ohrsmd424it926trbasffv9df",
//     "email_verified": true,
//     "event_id": "b6b9b41e-d895-461c-b9da-c3d39887db07",
//     "token_use": "id",
//     "auth_time": 1624235858,
//     "iss": "https://cognito-idp.us-west-1.amazonaws.com/us-west-1_RVJhltUNz",
//     "cognito:username": "cc714046-cb70-4ac8-babc-07446ef2d9a3",
//     "exp": 1624239458,
//     "iat": 1624235858,
//     "email": "yumindev@yahoo.com"
//   }

export async function signup({ email, password }) {
    const response = await fetch(`${LAMBDA_BASE_URL}/signup`, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain; charset=utf-8", // ! important
        },
        body: JSON.stringify({ email, password }),
    });

    const { ok, data } = await response.json();
    if (!ok) {
        throw new Error("Http Request Error");
    }

    return data;
}

export async function getAccessToken() {
    const userRawString = window.localStorage.getItem(LocalStorageKeys.UserRaw);
    if (!userRawString) return null;

    const userRaw = JSON.parse(userRawString);

    /**
     * ! Note: 有jwt，说明是firebase social login
     */
    if (userRaw.jwt && CURRENT_FIREBASE_AUTH_PROVIDER.includes(userRaw.authProvider)) {
        return userRaw.jwt;
    }

    // 下面的应该是aws cognito的token
    // ---------------下面开始： 判断accessToken是否过期，过期了就重新请求一个新的---------
    const { IdToken, AccessToken, RefreshToken } = userRaw;
    let decodedAccessToken = decodeJwt(AccessToken);
    const decodedIdToken = decodeJwt(IdToken);

    // * accessToken expired, now it expires in 1 day, which is the maxium value allowed
    if (decodedAccessToken.exp * 1000 <= Date.now()) {
        try {
            const response = await fetch(`${LAMBDA_BASE_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain; charset=utf-8", // ! important
                },

                body: JSON.stringify({ userId: decodedIdToken.sub, refreshToken: RefreshToken }),
            });

            const { ok, data } = await response.json();
            if (ok) {
                userRaw.AccessToken = data.userRaw.AuthenticationResult.AccessToken;
                userRaw.ExpiresIn = data.userRaw.AuthenticationResult.ExpiresIn;
                userRaw.TokenType = data.userRaw.AuthenticationResult.TokenType;
                userRaw.IdToken = data.userRaw.AuthenticationResult.IdToken;

                window.localStorage.setItem(LocalStorageKeys.UserRaw, JSON.stringify(userRaw));
            } else {
                throw new Error("Login with RefreshToken ERROR");
            }
        } catch (err) {
            // *: what if refreshToken is expired？
            // * https://stackoverflow.com/questions/64465114/how-to-get-aws-cognito-refresh-token-expiry
            // *: if refreshToken is expired, there will be an error
            // * refreshToken也可能过期，或各种原因导致的登录报错，那就强制退出，重新登录
            // * 目前设置的是180天过期，且没法客户端判断是否过期，只能请求aws，看是否报错
            window.localStorage.clear();
            window.location.href = window.location.origin;
        }
    }

    // ---------------------结束：判断accessToken是否过期---------

    return userRaw.AccessToken;
}
