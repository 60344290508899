import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { lazy } from "@loadable/component";
import { useSelector } from "react-redux";
import { MainViewPlaceholder } from "src/components";

const AppContainer = lazy(() => import(/* webpackChunkName: "app-container" */ "./AppContainer"));
const SideNav = lazy(() => import(/* webpackChunkName: "side-nav" */ "src/sidenav"));

const useStyles = makeStyles({
    AppContainer: {
        backgroundColor: "#FFF",
    },
});

export const Layout = ({ children }) => {
    const classes = useStyles();
    const appInitialized = useSelector((state) => state.app.appInitialized);

    return (
        <AppContainer classes={classes}>
            <SideNav />
            {appInitialized ? children : <MainViewPlaceholder />}
        </AppContainer>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
