import React, { useState, useCallback } from "react";
import { ListItem, ListItemText, ListItemIcon, ClickAwayListener, Box } from "@mui/material";
import PropTypes from "prop-types";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { updateWatchlists } from "src/helpers";
import { UserInfoType } from "src/types";

export function NavItem(props) {
    const {
        itemValue,
        itemLabel,
        isSelected,
        icons = {},
        handleNavItemOnClick,
        className,
        isWatchlistItem,
        watchlists,
        userInfo,
        setWatchlists,
        setActiveWatchlist,
        navigate,
        onGoHome,
        isLastWatchlistItem,
    } = props;

    const handleOnClick = useCallback(() => {
        handleNavItemOnClick(itemValue);
    }, [itemValue, handleNavItemOnClick]);

    // * controls watchlist item dropdown's open/close status
    const [open, setOpen] = useState(false);

    const handleWatchlistItemDropdownOnClick = useCallback(() => {
        setOpen((open) => !open);
    }, []);

    const handleWatchlistItemDropdownClickAway = useCallback(() => {
        setOpen(false);
    }, []);

    const handleRenameWatchlist = useCallback(async () => {
        const save = await window.Swal.fire({
            title: "New watchlist name",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Save",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !window.Swal.isLoading(),
        });

        const newName = save.value?.trim();
        if (save.isConfirmed) {
            if (!newName) {
                await window.Swal.fire("Try again?", "Watchlist name cannot be empty!", "error");
                return;
            }

            // * we don't need optimistic update here
            const newWls = watchlists.map((wl) => {
                if (wl.id === itemValue) {
                    return {
                        ...wl,
                        name: newName,
                    };
                }
                return wl;
            });

            let success = false;
            try {
                const result = await updateWatchlists({
                    userId: userInfo.id,
                    watchlists: newWls,
                });
                if (!result.watchlistsUpdated) {
                    throw new Error("Failed to update watchlists");
                }
                success = true;
                window.Swal.fire("Hooray~~", `New watchlist name is ${newName}`, "success");
            } catch (err) {
                window.Swal.fire("Try again?", "Failed to rename watchlist", "error");
            }
            if (success) {
                setWatchlists(newWls);
            }
        }
    }, [itemValue, setWatchlists, userInfo, watchlists]);

    const handleDeleteWatchlist = useCallback(async () => {
        const areYouSureAlert = await window.Swal.fire({
            title: "Are you sure?",
            text: `delete ${itemLabel} from the watchlists`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });
        if (areYouSureAlert.isConfirmed) {
            const watchlistsAfterDelete = watchlists.filter((wl) => wl.id !== itemValue);
            let success = false;
            try {
                const result = await updateWatchlists({
                    userId: userInfo.id,
                    watchlists: watchlistsAfterDelete,
                });
                if (!result.watchlistsUpdated) {
                    throw new Error("Failed to update watchlists");
                }
                success = true;
                window.Swal.fire({
                    text: `Watchlist "${itemLabel}" is deleted`,
                    icon: "success",
                });
            } catch (err) {
                window.Swal.fire("Try again?", "Failed to delete watchlist", "error");
                return;
            }

            if (success) {
                setWatchlists(watchlistsAfterDelete);
            }

            if (watchlistsAfterDelete.length > 0) {
                const firstWatchlistId = watchlistsAfterDelete[0].id;
                setActiveWatchlist(firstWatchlistId);
                navigate(`/watchlist/${firstWatchlistId}`);
            } else {
                navigate("/"); // go home
                onGoHome(); // reset a bunch of states
            }
        }
    }, [
        itemLabel,
        watchlists,
        setWatchlists,
        itemValue,
        userInfo,
        setActiveWatchlist,
        onGoHome,
        navigate,
    ]);

    return (
        <ListItem
            className={`SideNav-item ${className || ""} ${
                isWatchlistItem && "SideNav-item__watchlist"
            }`}
            selected={isSelected}
        >
            <ListItemIcon className="ListItemIcon_Placeholder" onClick={handleOnClick}>
                {icons[itemValue]}
            </ListItemIcon>
            <ListItemText onClick={handleOnClick}>{itemLabel}</ListItemText>
            {isWatchlistItem && (
                <ClickAwayListener onClickAway={handleWatchlistItemDropdownClickAway}>
                    <Box className="SideNav-item__watchlist__DropdownContainer">
                        <MoreVertIcon onClick={handleWatchlistItemDropdownOnClick} />
                        {open && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                position="absolute"
                                className={`SideNav-item__watchlist__DropdownContentContainer ${
                                    isLastWatchlistItem ? "SideNav-item__watchlist__lastitem" : ""
                                }`}
                            >
                                <Box onClick={handleRenameWatchlist}>Rename</Box>
                                <Box onClick={handleDeleteWatchlist}>Delete</Box>
                            </Box>
                        )}
                    </Box>
                </ClickAwayListener>
            )}
        </ListItem>
    );
}

NavItem.propTypes = {
    itemLabel: PropTypes.string.isRequired,
    itemValue: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    icons: PropTypes.object,
    handleNavItemOnClick: PropTypes.func,
    className: PropTypes.string,
    isWatchlistItem: PropTypes.bool,
    watchlists: PropTypes.arrayOf(PropTypes.object), // !TODO: PropTypes.exact
    userInfo: UserInfoType,
    setWatchlists: PropTypes.func,
    setActiveWatchlist: PropTypes.func,
    onGoHome: PropTypes.func,
    navigate: PropTypes.func,
    isLastWatchlistItem: PropTypes.bool,
};
