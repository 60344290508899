import React, { useCallback, useMemo } from "react";
import { Box, Chip } from "@mui/material";

import { Save as SaveIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { updateWatchlists, getOneSharedWatchlist } from "src/helpers";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    SaveSharedWatchlist: {
        paddingBottom: "5px",
    },
});

export function SaveSharedWatchlist() {
    const classes = useStyles();
    const currentWatchlists = useSelector((state) => state.app.watchlists);
    const userInfo = useSelector((state) => state.app.userInfo);
    const { sharedWatchlistId } = useParams();
    const handleOnSave = useCallback(async () => {
        if (!userInfo) {
            window.location.href = "/user/auth";
            return;
        }
        try {
            const { filters, name, tickers, blocked_websites } = await getOneSharedWatchlist(
                sharedWatchlistId
            );
            const newWatchlists = [
                ...currentWatchlists,
                {
                    name,
                    owner: {
                        email: userInfo.email,
                    },
                    created_at: Date.now(),
                    id: uuidv4(),
                    filters,
                    tickers,
                    blocked_websites,
                },
            ];
            const result = await updateWatchlists({
                userId: userInfo.id,
                watchlists: newWatchlists,
            });
            if (!result.watchlistsUpdated) {
                throw new Error("Failed to update watchlists");
            }

            window.Swal.fire({
                text: "Saved to your watchlists",
                icon: "success",
                timer: 1500,
                timerProgressBar: true,
            });
            window.location.reload();
        } catch (err) {
            console.error("error: ", err);
        }
    }, [currentWatchlists, sharedWatchlistId, userInfo]);

    const ChipStyle = useMemo(() => {
        return {
            backgroundColor: "#024D8F",
            color: "#fff",
            "&:focus": {
                backgroundColor: "#024D8F",
            },
            "&:hover": {
                backgroundColor: "#024D8F",
            },
            "& > svg > path": {
                color: "#fff",
            },
        };
    }, []);

    return (
        <Box display="flex" className={classes.SaveSharedWatchlist}>
            <Chip
                icon={<SaveIcon />}
                key={"Share"}
                label={"Save to my watchlists"}
                onClick={handleOnSave}
                sx={ChipStyle}
            />
        </Box>
    );
}
