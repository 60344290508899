export const SX = {
    OperationChip: {
        backgroundColor: "#024D8F",
        "&:focus": {
            backgroundColor: "#024D8F",
        },
        "&:hover": {
            backgroundColor: "#024D8F",
        },
        color: "#fff",
        marginRight: "10px",
        marginBottom: "5px",
        "& > svg > path": {
            color: "#fff",
        },
    },
    FilterDropdownContainer: {
        position: "absolute",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        width: "300px",
        maxHeight: "450px",
        overflowY: "scroll",
        "@media screen and (max-width: 1280px)": {
            width: "283px",
        },
        top: "40px",
        left: "0px",
        zIndex: 1,
        padding: "16px",
        boxShadow:
            // eslint-disable-next-line
            "0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
    },
    FilterDropdownPrimaryTitle: {
        padding: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > svg": {
            color: "black",
        },
    },
    FilterSectionTitle: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        height: "42px",
        display: "flex",
        alignItems: "center",
        color: "black",
    },
    FormControl: {},
    FormControlItem: {
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: "#67AFE7",
        },
        "& .MuiFormControlLabel-label": {
            fontSize: "0.8rem",
            color: "black",
        },
    },
};
