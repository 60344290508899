import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";

const knownErrors = {};

function MaybeAvatar(props) {
    const [avatarProps, setAvatarProps] = useState(props);

    useEffect(() => {
        const imgProps = props.imgProps || {};
        const onError = imgProps.onError || (() => {});
        setAvatarProps({
            ...props,
            src: knownErrors[props.src] ? null : props.src,
            imgProps: {
                ...imgProps,
                onError: (event) => {
                    knownErrors[props.src] = true;
                    setAvatarProps({
                        ...props,
                        src: null,
                    });
                    onError(event);
                },
            },
        });
    }, [props]);

    return <Avatar {...avatarProps} />;
}

MaybeAvatar.propTypes = {
    imgProps: PropTypes.object,
    src: PropTypes.string,
};

export default MaybeAvatar;
