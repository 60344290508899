import React, { useCallback } from "react";
import { ReactComponent as LogoSvg } from "src/images/logo-full.svg";
import "./Logo.scss";
import { useNavigate } from "react-router-dom";

export function Logo() {
    const navigate = useNavigate();

    const goToHome = useCallback(() => {
        navigate("/");
    }, [navigate]);
    return (
        <div className="logo__container" onMouseUp={goToHome} role="button" tabIndex={0}>
            <span className="logo__svg">
                <LogoSvg />
            </span>
        </div>
    );
}
