import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { Box, FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { kFullFilterObject } from "src/helpers";
import { SX } from "./utils";

export const FilterSection = (props) => {
    const { groupValue, groupLabel, internalFilter, handleFilterCheckboxOnClick } = props;

    const handleCheckboxOnChange = useCallback(
        ({ target: { value: filterSectionItemValue, checked } }) => {
            handleFilterCheckboxOnClick(groupValue, filterSectionItemValue, checked);
        },
        [groupValue, handleFilterCheckboxOnClick]
    );
    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column">
                <Box component="span" sx={SX.FilterSectionTitle}>
                    {groupLabel}
                </Box>
            </Box>
            <FormControl component="fieldset" sx={SX.FormControl}>
                <FormGroup>
                    {Object.values(kFullFilterObject[groupValue]).map((exclItem) => {
                        return (
                            <FormControlLabel
                                key={exclItem.value}
                                sx={SX.FormControlItem}
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            internalFilter?.[groupValue]?.[exclItem.value]
                                        )}
                                        onChange={handleCheckboxOnChange}
                                        name={exclItem.value}
                                        value={exclItem.value}
                                    />
                                }
                                label={exclItem.label}
                            />
                        );
                    })}
                </FormGroup>
            </FormControl>
        </React.Fragment>
    );
};

FilterSection.propTypes = {
    internalFilter: PropTypes.object,
    handleFilterCheckboxOnClick: PropTypes.func.isRequired,
    groupValue: PropTypes.string.isRequired,
    groupLabel: PropTypes.string.isRequired,
};
