// side effects (epics)
import * as userActions from "./user-actions";
import { combineEpics, ofType } from "redux-observable";
import { mergeMap, map, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { LAMBDA_BASE_URL } from "src/api";
import * as appActions from "src/redux/app/app-actions";
import { nanoid } from "nanoid";

const TICKER_TICK_GUEST_ID = "TICKER_TICK_GUEST_ID";

const getUserId = (id) => {
    if (id) {
        window.localStorage.removeItem(TICKER_TICK_GUEST_ID);
        return id;
    }

    const guestId = window.localStorage.getItem(TICKER_TICK_GUEST_ID);

    if (guestId) {
        return guestId;
    }

    const _id = nanoid();
    window.localStorage.setItem(TICKER_TICK_GUEST_ID, _id);
    return _id;
};

const onLoggingUserBehaviorEpic = (action$, state$) => {
    return action$.pipe(
        ofType(userActions.ON_LOGGING_USER_BEHAVIOR),
        mergeMap((action) => {
            const { storyId, actionType, actionValue, tags } = action.payload;
            const state = state$.value;

            const userId = getUserId(state.app.userInfo?.id);

            return ajax({
                url: `${LAMBDA_BASE_URL}/crud`,
                method: "POST",
                headers: {
                    "Content-Type": "text/plain; charset=utf-8", // ! important
                },
                body: JSON.stringify({
                    operation: "SAVE_USER_ACTION", // !TODO: should be log user behavior
                    accessToken: state.app.userRaw?.AccessToken,
                    userId,
                    storyId,
                    actionType,
                    actionValue,
                    tags,
                }),
            }).pipe(
                map((_response) => {
                    return userActions.onLoggingUserBehaviorDone();
                }),
                catchError((error) => {
                    return of(error);
                })
            );
        })
    );
};

const onUpdateBlocklistsEpic = (action$, state$) => {
    return action$.pipe(
        ofType(userActions.ON_UPDATE_BLOCKLISTS),
        mergeMap((action) => {
            const { site } = action.payload;
            const state = state$.value;

            const activeWatchlist = state.app.activeWatchlist;
            const userId = state.app.userInfo?.id;
            const accessToken = state.app.userRaw.AccessToken || state.app.userInfo.jwt;

            const body = {
                operation: "UPDATE_WATCHLISTS",
                accessToken,
                userId,
                watchlists: state.app.watchlists.map((wl) => {
                    if (wl.id === activeWatchlist) {
                        return {
                            ...wl,
                            blocked_websites: Array.isArray(wl.blocked_websites)
                                ? [...wl.blocked_websites, site]
                                : [site],
                        };
                    }
                    return wl;
                }),
            };
            return ajax({
                url: `${LAMBDA_BASE_URL}/crud`,
                method: "POST",
                headers: {
                    "Content-Type": "text/plain; charset=utf-8", // ! important
                },
                body: JSON.stringify(body),
            }).pipe(
                map((_response) => {
                    return userActions.onUpdateBlocklistsDone({
                        Item: _response.response.data.result.Item,
                    });
                }),
                catchError((error) => {
                    return of(error);
                })
            );
        })
    );
};

const onUpdateBlocklistDoneEpic = (actions$) => {
    return actions$.pipe(
        ofType(userActions.ON_UPDATE_BLOCKLISTS_DONE),
        concatMap((action) => {
            const { Item } = action.payload;
            // * since I don't know which field is updated, i just update all of them
            return [appActions.setWatchlists({ watchlists: Item.watchlists })];
        }),
        catchError((error) => {
            return of(error);
        })
    );
};

export const userEpics = combineEpics(
    onLoggingUserBehaviorEpic,
    onUpdateBlocklistsEpic,
    onUpdateBlocklistDoneEpic
);
