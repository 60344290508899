/* eslint-disable */
import React from "react";
import "./Footer.scss";
import { Button, Box, Link } from "@mui/material";

export function Footer() {
    return (
        <div className="SideNav-Footer-Container">
            <Button variant="outlined" className="SideNavFooterLongButton">
                <Link
                    href={`${window.location.origin}/feedback.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Disqus Comments
                </Link>
            </Button>
            <div className="v-spacer"></div>
            <Box component="div" display="flex" justifyContent="center" className="SideNav-Info">
                <Button>PRIVACY</Button>
                <Button>TERMS</Button>
                <Button>
                    <a
                        href="mailto:mail@tickertick.com?subject=Feedback%20for%20TickerTick"
                        target="_blank"
                        rel="noreferrer"
                        className="SideNav-Info__Link"
                    >
                        CONTACT
                    </a>
                </Button>
            </Box>
        </div>
    );
}
