import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, List, ListItem, CircularProgress } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { StoryItem } from "./StoryItem";
import { useSelector } from "react-redux";
import { LocalStorageKeys } from "src/helpers";

const useStyles = makeStyles(() => ({
    StoriesContainer: {
        maxWidth: "980px",
        width: "100%",
    },
    loadingTrigger: {
        alignItems: "center",
        flexDirection: "column",
    },
}));

export function Stories(props) {
    const classes = useStyles();
    const [loadingTriggerNode, setLoadingTriggerNode] = useState();
    const loadingTriggerObserver = useRef(null);

    const userInfo = useSelector((state) => state.app.userInfo);

    const { loadMore, stories, loading, ended } = props;

    useEffect(() => {
        if (!window.IntersectionObserver) {
            return;
        }

        if (loadingTriggerObserver.current) {
            loadingTriggerObserver.current.disconnect();
        }

        const loadMoreStatus = window.localStorage.getItem(LocalStorageKeys.LoadMoreStatus)
            ? JSON.parse(window.localStorage.getItem(LocalStorageKeys.LoadMoreStatus))
            : {
                  error_timestamp: 0,
              };

        const canLoadMore = loadMoreStatus.error_timestamp + 60 * 1000 < Date.now();

        if (loadingTriggerNode) {
            const cb = (entries) => {
                if (canLoadMore) {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && loadMore) {
                            loadMore();
                        }
                    });
                }
            };
            const opts = { threshold: 0 }; // * cb will be called even if 1 pixel is visible
            loadingTriggerObserver.current = new IntersectionObserver(cb, opts);
            loadingTriggerObserver.current.observe(loadingTriggerNode);
        }

        return () => {
            if (loadingTriggerObserver.current) {
                loadingTriggerObserver.current.disconnect();
            }
        };
    }, [loadingTriggerNode, loadMore]);

    return stories.length === 0 ? (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            maxWidth="1200px"
        >
            {loading ? <CircularProgress /> : <h1>No news at this moment</h1>}
        </Box>
    ) : (
        <List disablePadding className={classes.StoriesContainer}>
            {stories.map((story, index) => {
                return (
                    <StoryItem
                        {...story}
                        isFirst={index === 0}
                        key={story.id}
                        userInfo={userInfo}
                    />
                );
            })}

            <ListItem
                ref={setLoadingTriggerNode /** keyword: callback refs */}
                className={classes.loadingTrigger}
            >
                {loading ? (
                    <CircularProgress />
                ) : ended ? (
                    <h4>No more stories</h4>
                ) : (
                    <Button color="primary" startIcon={<ExpandMoreIcon />} onClick={loadMore}>
                        Load More
                    </Button>
                )}
            </ListItem>
        </List>
    );
}

Stories.propTypes = {
    loadMore: PropTypes.func,
    stories: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool.isRequired,
    ended: PropTypes.bool,
};
