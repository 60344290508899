import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { Header } from "./Header";
import { NavItems } from "./NavItems";
import { Footer } from "./Footer";
import "./BaseSideNav.scss";
import { LocalStorageKeys } from "src/helpers/const-helpers";
import decodeJwt from "jwt-decode";
import { firebaseAuth } from "src/components/SocialLogin/firebase-config";

export function BaseSideNav({ setDisplaySidenav }) {
    // update jwt token whenever it's expired if it's firebase social login
    useEffect(() => {
        try {
            const userRawString = window.localStorage.getItem(LocalStorageKeys.UserRaw);
            if (!userRawString) return null;

            const userRaw = JSON.parse(userRawString);
            // ! ATTENTION: email signup still uses cognito, it does not have jwt
            if (!userRaw.jwt) return;

            const decoded = decodeJwt(userRaw.jwt);

            if (decoded.iss === "https://securetoken.google.com/ticker-tick") {
                if (Date.now() > decoded.exp * 1000) {
                    firebaseAuth.onIdTokenChanged((user) => {
                        const newJwtToken = user.stsTokenManager.accessToken;
                        userRaw.jwt = newJwtToken;

                        window.localStorage.setItem(
                            LocalStorageKeys.UserRaw,
                            JSON.stringify(userRaw)
                        );
                    });
                }
            }
        } catch (err) {
            window.Sentry?.captureException(err);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" className="SideNav-Container">
            <Header setDisplaySidenav={setDisplaySidenav} />
            <NavItems />
            <Footer />
        </Box>
    );
}

BaseSideNav.propTypes = {
    setDisplaySidenav: PropTypes.func,
};
